import React, { createContext, useEffect, useMemo, useState } from "react";
import { WrapperType } from "../components/enum";

export const AppContext = createContext(); // could have a default value

export const AppProvider = ({ children }) => {
    const [version, setVersion] = useState(1);

    const value = useMemo(() => ({
        version, setVersion
    }), [version]);

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}