import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { useScrollToTop } from '../services/hook';

const Pagination = (props) => {
    const { onPageChange, page, last_page, total, limit } = props;

    useScrollToTop([page]);

    if (last_page <= 1 || (total && limit && total <= limit)) return <></>

    return (
        <div className='d-flex flex-center'>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={({ selected }) => { onPageChange(selected + 1) }}
                // initialPage={page - 1}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                pageCount={last_page ? last_page : total && limit ? total % limit === 0 ? total / limit : (total / limit) + 1 : 1}
                previousLabel="<"
                renderOnZeroPageCount={null}
                className="pagination"
                disabledClassName="page-item-disable"
                breakClassName="page-item break"
                breakLinkClassName="page-link"
                activeClassName="active"
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link page-number"
                previousLinkClassName="page-link previous"
                nextLinkClassName="page-link next"
            />
        </div>
    )
}

export default Pagination