export async function setToken(value) {
    await localStorage.setItem('token', value);
}

export function getToken() {
    return localStorage.getItem('token');
}

export async function setLanguage(value) {
    await localStorage.setItem('language', value);
}

export function getLanguage() {
    return localStorage.getItem('language');
}

export async function setUserId(value) {
    console.log('user_id => ' + value);
    await localStorage.setItem('user_id', value);
}

export function getUserId() {
    return localStorage.getItem('user_id');
}

export async function setTempToken(value) {
    await localStorage.setItem('temp_token', value);
}

export function getTempToken() {
    return localStorage.getItem('temp_token');
}

export async function setWalletAddress(value) {
    await localStorage.setItem('wallet_address', value);
}

export function getWalletAddress() {
    return localStorage.getItem('wallet_address');
}

export async function setLocalIdToken(value) {
    await localStorage.setItem('web3auth_id_token', value);
}

export function getLocalIdToken() {
    return localStorage.getItem('web3auth_id_token');
}

export async function setLocalIsDoneIDMS(value) {
    await localStorage.setItem('is_idms_done', value);
}

export function getLocalIsDoneIDMS() {
    return localStorage.getItem('is_idms_done');
}

export async function setLocalIsDoneMetamask(value) {
    await localStorage.setItem('is_metamask_done', value);
}

export function getLocalIsDoneMetamask() {
    return localStorage.getItem('is_metamask_done');
}

export async function setRedirectUriAfterRegister(value) {
    await localStorage.setItem('redirect_uri_after_register', value);
}
export function getRedirectUriAfterRegister() {
    return localStorage.getItem('redirect_uri_after_register');
}

export async function setReceiptName(value) {
    await localStorage.setItem('receipt_name', value);
}

export function getReceiptName() {
    return localStorage.getItem('receipt_name');
}

export async function removeValue(key) {
    await localStorage.removeItem(key);
}

export async function setValue(key, value) {
    await localStorage.setItem(key, JSON.stringify(value));
}

export async function getValue(key) {
    return JSON.parse(await localStorage.getItem(key));
}

export function clearLocalStorage() {
    return localStorage.clear();
}
