import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import {
  Button,
  TextBox,
  TextBoxWithLabel,
  TextBoxWithLabel2,
} from "../components/Form";
import { postData } from "../services/fetch";
import { translations } from "../services/localization";
import Swal from "../components/Swal";
import ContactModal from "../Modal/ContactModal";
import Modal from "../components/Modal";

const Contact = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const content = translations.home.contact;
  const send = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await postData(`api/contacts/store-contact-message`, {
      name,
      email,
      message,
    });
    if (response.ok) {
      setIsSent(true);
      setIsSuccess(true);
      console.log("submit", response);
      // Swal.fire({
      //   title: "お問い合わせありがとうございます。",
      //   text: "担当者から2-3営業日以内にご連絡いたしますので、ばらくお待ちください。",
      // });
    }
    setLoading(false);
  };

  const clear = () => {
    setName("");
    setEmail("");
    setMessage("");
    setIsSent(false);
  };

  useEffect(() => {
    return () => clear();
  }, []);

  return (
    <div className="feature py-5 bg-2 " id="contact">
      <div className="container">
        <div className="p-faq">
          <div className="col-12 mb-5">
            {/* <h2 className="text-center title">{content.title}</h2> */}
          </div>
          {/* {isSent ? ( */}
          {!isSent && (
            <div className="col-12 mb-3">
              <form onSubmit={send}>
                <div className="row">
                  <div className="col-sm-12 col-md-6 mb-4">
                    <TextBoxWithLabel2
                      label={content.labelName}
                      variant={2}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 mb-4 ">
                    <TextBoxWithLabel2
                      type="email"
                      label={content.labelEmail}
                      variant={2}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-5">
                    <TextBoxWithLabel2
                      textarea={1}
                      label={content.labelMessage}
                      variant={2}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-12 mb-4 text-center">
                    <Button
                      type="button"
                      loading={loading}
                      data-spinner-color="#495376"
                      className="btn btn-mini fw-100 btn-dim"
                      style={{ height: 50, fontSize: 18 }}
                      onClick={send}
                    >
                      {content.buttonSend}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {/* ) : ( */}
          <Modal
            isOpen={isSuccess}
            onRequestClose={() => setIsSuccess(false)}
            noClose={true}
          >
            <p className="custom_modal_title">
              お問い合わせありがと
              <br />
              うございます。
            </p>
            <span className="custom_modal_text">
              担当者から2-3営業日以内にご
              <br />
              連絡いたしますので、 ばらくお待ちください。
            </span>
            <div className="d-flex justify-content-center">
              <div className="w-75">
                {/* <Button
                    variant={6}
                    onClick={() => null}
                    className="btn-hover w-75"
                  >
                    OK
                  </Button> */}
                <Button
                  variant={2}
                  className="w-50 mb-4"
                  onClick={() => setIsSuccess(false)}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
