import Web3 from "web3";
import {
  REACT_APP_ARCANA_CONTRACT_ADDRESS,
  REACT_APP_PERSONA_CONTRACT_ADDRESS,
  REACT_APP_CHAIN_ID,
  REACT_APP_INCUBATOR_CONTRACT_ADDRESS,
  REACT_APP_MP_WALLET_ADDRESS,
  REACT_APP_NULL_ADDRESS,
  REACT_APP_RPC_TARGET,
  REACT_APP_WALLET_CLIENT_ID,
  REACT_APP_EGG_CONTRACT_ADDRESS,
  REACT_APP_WEB3AUTH_VERIFIER_NAME,
  REACT_APP_WEB3AUTH_NETWORK,
} from "../config";
import Incubator from "../abi/Incubator.json";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import erc20ABI from "../abi/erc20ABI.json";
import { TokenType } from "./enum";
import Swal from "./Swal";
import { Web3AuthNoModal } from "@web3auth/no-modal"
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { calculateBN, toBigNumber } from "./commonFnc";

// const maxPriorityFeePerGas = "5000000000"; // Max priority fee per gas
// const maxFeePerGas = "6000000000000"; // Max fee per gas
// const maxPriorityFeePerGas = "50000000000"; // Max priority fee per gas
// const maxFeePerGas = "150000000000"; // Max fee per gas
const maxPriorityFeePerGas = "50000000000"; // Max priority fee per gas
const maxFeePerGas = "150000000000"; // Max fee per gas

export default class EthereumRpc {
  provider;

  constructor(provider) {
    this.provider = provider;
  }

  async getChainId() {
    try {
      const web3 = new Web3(this.provider);

      // Get the connected Chain's ID
      const chainId = await web3.eth.getChainId();

      return chainId + "";
    } catch (error) {
      return error;
    }
  }

  async getAccounts() {
    try {
      const web3 = new Web3(this.provider);

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      return address;
    } catch (error) {
      return error;
    }
  }

  async getBalance() {
    try {
      const web3 = new Web3(this.provider);

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      // Get user's balance in ether
      const balance = web3.utils.fromWei(
        await web3.eth.getBalance(address) // Balance is in wei
      );

      return balance;
    } catch (error) {
      return error;
    }
  }

  async getAnimaDecimal() {
    try {
      const web3 = new Web3(this.provider);
      const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Anima), TokenType.getContractAddress(TokenType.Anima));

      return await contract.methods.decimals().call();
    } catch (error) {
      return error;
    }
  }

  async getAnimaBalance(address = null, decimal_places = 0) {
    try {
      const web3 = new Web3(this.provider);
      if (!address) {
        // Get user's Ethereum public address
        address = (await web3.eth.getAccounts())[0];
      }

      const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Anima), TokenType.getContractAddress(TokenType.Anima));

      // Read message from smart contract
      const decimals = await contract.methods.decimals().call();

      // Read message from smart contract
      const balance = await contract.methods.balanceOf(address).call();

      return calculateBN(balance, decimals, decimal_places)
    } catch (error) {
      return error;
    }
  }

  async getApproved(token_type_id, tokenId) {
    const web3 = new Web3(this.provider);

    const contract = new web3.eth.Contract(
      erc20ABI,
      this.getContractAddress(token_type_id)
    );

    // Send transaction to smart contract to update message and wait to finish
    return contract.methods.getApproved(tokenId).call();
  }

  async ownerOf(token_type_id, tokenId) {
    const web3 = new Web3(this.provider);

    const contract = new web3.eth.Contract(
      erc20ABI,
      this.getContractAddress(token_type_id)
    );

    // Send transaction to smart contract to update message and wait to finish
    return contract.methods.ownerOf(tokenId).call();
  }

  async isMinter(token_type_id, address) {
    const web3 = new Web3(this.provider);

    const contract = new web3.eth.Contract(
      erc20ABI,
      this.getContractAddress(token_type_id)
    );

    // Send transaction to smart contract to update message and wait to finish
    return contract.methods.minters(address).call();
  }

  async balanceOf(token_type_id, address) {
    const web3 = new Web3(this.provider);

    const contract = new web3.eth.Contract(
      erc20ABI,
      this.getContractAddress(token_type_id)
    );

    // Send transaction to smart contract to update message and wait to finish
    return contract.methods.balanceOf(address).call();
  }

  async generateArkana(eggTokenId, toAddress, seed, signature) {
    try {
      const web3 = new Web3(this.provider);

      const incubatorContract = new web3.eth.Contract(
        Incubator,
        REACT_APP_INCUBATOR_CONTRACT_ADDRESS
      );
      try {
        const test = await incubatorContract.methods
          .incubate(eggTokenId, toAddress, seed, signature)
          .send({ from: toAddress });
        console.log(test);
      } catch (e) {
        console.error(e);
      }

      return test;
    } catch (error) {
      return error;
    }
  }

  async sendTransaction() {
    try {
      const web3 = new Web3(this.provider);

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const destination = fromAddress;

      const amount = web3.utils.toWei("0.001"); // Convert 1 ether to wei

      // Submit transaction to the blockchain and wait for it to be mined
      const receipt = await web3.eth.sendTransaction({
        from: fromAddress,
        to: destination,
        value: amount,
        maxPriorityFeePerGas: "5000000000", // Max priority fee per gas
        maxFeePerGas: "6000000000000", // Max fee per gas
      });

      return receipt;
    } catch (error) {
      return error;
    }
  }

  async signMessage(message = "YOUR_MESSAGE") {
    try {
      const web3 = new Web3(this.provider);

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const originalMessage = message;

      // Sign the message
      console.log("SIGN", [originalMessage, fromAddress]);
      const signedMessage = await web3.eth.personal.sign(
        originalMessage,
        fromAddress,
        null
      );

      return signedMessage;
    } catch (error) {
      return error;
    }
  }

  async getPrivateKey() {
    try {
      const privateKey = await this.provider.request({
        method: "eth_private_key",
      });

      return privateKey;
    } catch (error) {
      return error;
    }
  }

  async approveContract(token_type_id, token_id, isCancelled = false) {
    const web3 = new Web3(this.provider);

    // Get user's Ethereum public address
    const fromAddress = (await web3.eth.getAccounts())[0];

    const contract = new web3.eth.Contract(TokenType.getABI(token_type_id), TokenType.getContractAddress(token_type_id));
    console.log({ address: isCancelled ? REACT_APP_NULL_ADDRESS : REACT_APP_MP_WALLET_ADDRESS, token_id })
    // Send transaction to smart contract to update message and wait to finish
    return new Promise((respond, reject) => {
      contract.methods.approve(isCancelled ? REACT_APP_NULL_ADDRESS : REACT_APP_MP_WALLET_ADDRESS, token_id).send({
        from: fromAddress,
        maxPriorityFeePerGas,
        maxFeePerGas
      }).on('transactionHash', function (hash) {
        respond(hash);
      }).catch(error => {
        console.log(error);
        if (error.receipt) {
          console.log(error.receipt.revertReason);
          const web3 = new Web3(this.provider);
          console.log(web3.utils.hexToAscii(error.receipt.revertReason));
          return web3.utils.hexToAscii(error.receipt.revertReason);
        }
        Swal.fire({
          icon: "error",
          title: "エラーが発生しました",
          text: error.message
            ? error.message
            : error.data.message || error.data,
        }).then(() => window.location.reload());
      });
    })
  }

  // for arcana & persona, amount is token_id. For anima, amount is anima's amount.
  async transferContract(token_type_id, to, amount) {
    const web3 = new Web3(this.provider);

    // Get user's Ethereum public address
    const fromAddress = (await web3.eth.getAccounts())[0];

    const contract = new web3.eth.Contract(TokenType.getABI(token_type_id), TokenType.getContractAddress(token_type_id));

    const options = {
      from: fromAddress,
      maxPriorityFeePerGas,
      maxFeePerGas,
    };

    if (Number(token_type_id) === TokenType.Anima) {
      const decimals = await contract.methods.decimals().call();
      amount = toBigNumber(amount, decimals)

      return new Promise((respond, reject) => {
        contract.methods.transfer(to, amount).send(options).once('transactionHash', function (hash) {
          respond(hash);
        }).catch(error => {
          console.log(error)
          // Swal.fire({
          //   icon: "error",
          //   title: "エラーが発生しました",
          //   text: error.message,
          // });
        });
      })
    } else {
      // Send transaction to smart contract to update message and wait to finish
      return new Promise((respond, reject) => {
        contract.methods
          .transferFrom(fromAddress, to, amount)
          .send(options)
          .once("transactionHash", function (hash) {
            respond(hash);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "エラーが発生しました",
              text: error.message,
            });
          });
      });
    }
  }

  getContractAddress(token_type_id) {
    if (token_type_id == TokenType.Arcana) {
      return REACT_APP_ARCANA_CONTRACT_ADDRESS;
    }
    if (token_type_id == TokenType.Persona) {
      return REACT_APP_PERSONA_CONTRACT_ADDRESS;
    }
    if (token_type_id == 100) {
      return REACT_APP_EGG_CONTRACT_ADDRESS;
    }
    return "";
  }
}
export const initWeb3 = () => {
  return new Web3AuthNoModal({
    clientId: REACT_APP_WALLET_CLIENT_ID,
    chainConfig: {
      // this is ethereum chain config, change if other chain(Solana, Polygon)
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: REACT_APP_CHAIN_ID,
      rpcTarget: REACT_APP_RPC_TARGET,
    },
  });
};

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: REACT_APP_CHAIN_ID,
  rpcTarget: REACT_APP_RPC_TARGET,
  // displayName: "Ethereum Mainnet",
  // blockExplorer: "https://etherscan.io",
  // ticker: "ETH",
  // tickerName: "Ethereum",
};

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});

export const openloginAdapter = () => {
  console.log("VERIFIER:" + REACT_APP_WEB3AUTH_VERIFIER_NAME);
  return new OpenloginAdapter({
    privateKeyProvider,
    adapterSettings: {
      clientId: REACT_APP_WALLET_CLIENT_ID,
      network: REACT_APP_WEB3AUTH_NETWORK,
      // chainConfig: {
      //   // this is ethereum chain config, change if other chain(Solana, Polygon)
      //   chainNamespace: CHAIN_NAMESPACES.EIP155,
      //   chainId: REACT_APP_CHAIN_ID,
      //   rpcTarget: REACT_APP_RPC_TARGET,
      // },
      uxMode: "redirect",
      whiteLabel: {
        name: " ",
        logoLight: "https://anicana.org/levica.svg",
        logoDark: "https://anicana.org/levica.svg",
        defaultLanguage: "en",
        dark: true, // whether to enable dark mode. defaultValue: false
      },
      loginConfig: {
        jwt: {
          name: "ANICANA PORTAL",
          verifier: REACT_APP_WEB3AUTH_VERIFIER_NAME,
          typeOfLogin: "jwt",
          clientId: "1",
        },
      },
    },
  });
};

const loginMethods = {
  google: { showOnModal: false },
  facebook: { showOnModal: false },
  twitter: { showOnModal: false },
  reddit: { showOnModal: false },
  discord: { showOnModal: false },
  twitch: { showOnModal: false },
  apple: { showOnModal: false },
  line: { showOnModal: false },
  github: { showOnModal: false },
  kakao: { showOnModal: false },
  linkedin: { showOnModal: false },
  weibo: { showOnModal: false },
  wechat: { showOnModal: false },
  email_passwordless: { showOnModal: true },
};

export const web3authModalParams = {
  modalConfig: {
    [WALLET_ADAPTERS.OPENLOGIN]: {
      //loginMethods,
      label: "openlogin",
      showOnModal: true,
    },
  },
};
