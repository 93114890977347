import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatMoney, getTokenDetail } from '../../components/commonFnc'
import { Button } from '../../components/Form'
import Loading from '../../components/Loading'
import MyPageWrapper from '../../components/MyPageWrapper'
import Pagination from '../../components/Pagination'
import RNDataTable from '../../components/RNDataTable'
import SubNav from '../../components/SubNav'
import { getData } from '../../services/fetch'
import { translations } from "../../services/localization";
import { paymentCurrency } from '../../components/enum'

const ReceiveOffer = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getReceivedOffers(page);

    return () => {
      setData([]);
    }
  }, [page])

  const getReceivedOffers = async () => {
    setLoading(true);
    var response = await getData(`api/orders/get-received-offers?page=${page}`, true);
    if (response.ok) {
      var data = JSON.parse(JSON.stringify(response.data.received_offers.data));
      // for(var i = 0; i < data.length; i++) {
      //   data[i]["token"] = await getTokenDetail(data[i].token_id);
      // }
      setData(data);
      setPage(response.data.received_offers.current_page);
      setLast_page(response.data.received_offers.last_page);
    }
    setLoading(false);
  }
  const columns = [
    {
      name: 'トークン',
      selector: row => row.token_id,
      cell: row =>
        <div className='d-flex align-items-center token-cell'>
          <Link to={`/detail/${row.token_type_id}/${row.token_id}`}>
            <img src={row.token ? row.token.image : ''} />
          </Link>
          <div className='d-flex flex-column'>
            <Link className='mb-2 name text-reset' to={`/detail/${row.token_type_id}/${row.token_id}`}>{row.token.name}</Link>
          </div>
        </div>,
      center: true,
      width: '300px',
      style: {
        justifyContent: 'flex-start',
      },
    },
    // {
    //   name: 'SP',
    //   right: true,
    // },
    {
      name: '買値',
      selector: row => formatMoney(row.amount, row.currency),
      right: true,
    },
    {
      name: '受信日時',
      selector: row => row.created_at,
      right: true,
    },
    {
      name: '現在価格',
      selector: row => formatMoney((Number(row.currency) === paymentCurrency.Anima? row.token?.anm?.bid : row.token?.jpy?.bid) || 0, row.currency),
      right: true
    },
    {
      name: 'ステータス',
      selector: row => row.status == "cancelled" ? <span className='td-status'>キャンセル済み</span> :
        row.status == "active" ? <span className='td-status'>注文中</span> :
          row.status == "nonactive" ? (row.type == "sell" ? <span className='td-status'>トランザクション確認中</span> : <span className='td-status'>決済待ち</span>) :
            row.status == "matched" ?
              <div className='contract'>
                <span className='td-status'>Contract</span>
                <Button variant={4} onClick={() => { }} className="w-100">{'取引詳細'}</Button>
              </div> : <></>,
      center: true
    }
  ];
  return (
    <MyPageWrapper pageid='受信オファー'>
      <SubNav pageid='受信オファー' items={[
        {
          id: '受信オファー',
          name: translations.myPageWrapper.list.incomingOffers,
          to: '/mypage/receive-offer',
        },
        {
          id: '送信オファー',
          name: translations.myPageWrapper.list.outgoingOffers,
          to: '/mypage/send-offer',
        },
      ]} />
      <div className='d-flex flex-row align-items-end mb-3 d-none d-sm-block'>
        <h3 className='text-start'>{translations.myPageWrapper.list.incomingOffers}</h3>
      </div>
      {
        loading ? <Loading /> :
          <>
            <RNDataTable
              columns={columns}
              data={data}
              noDataComponent={<span style={{ marginTop: 50 }}>{translations.emptylist_messages.noOrderHistory}</span>}
            />
            <Pagination onPageChange={page => setPage(page)} page={page} last_page={last_page} />
          </>
      }
    </MyPageWrapper>
  )
}

export default ReceiveOffer
