import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SidebarContext } from "../context/appContext";
import { logout } from "../services/fetch";
import { WrapperType } from "./enum";
import Footer from "./Footer";
import { Button, SortSelect } from "./Form";
import { dispatch } from "./global";
import {
  ArcanaIcon,
  AskIcon,
  BidIcon,
  BookmarkIcon,
  BuyIcon,
  CloseIcon,
  FilterIcon,
  FunctionIcon,
  HistoryIcon,
  LogoutIcon,
  PaymentIcon,
  PersonaIcon,
  SellIcon,
  SettingIcon,
  SortIcon,
  Word_AIcon,
  Word_PIcon,
} from "./Icon";
import { ReactComponent as AnicanaLogoImg } from "../assets/images/anicana_logo.svg";
import Modal from "../components/Modal";
import { translations } from "../services/localization";

const MyPageWrapper = (props) => {
  const [showWrapper, setShowWrapper] = useState(false);
  useEffect(() => {
    dispatch({ type: "update", key: "sidebarType", value: WrapperType.MyPage });
  }, []);

  return (
    <div id="mypage">
      <div id="wrapper" className={`wrapper-content mypage-wrapper`}>
        <div className="d-flex">
          <div className="sidebar-col pe-0 d-none d-lg-block">
            <MyPageSidebar {...props} showWrapper={showWrapper} />
          </div>
          <MyPageSidebar
            {...props}
            setShowWrapper={setShowWrapper}
            className={`d-block d-lg-none sidebar-fullscreen ${
              showWrapper ? "show" : ""
            }`}
          />

          <div className={`w-100 side-content ${props.sideContentClass || ""}`}>
            <div id="page-content-wrapper p-0">
              {/* {
                                !props.disabledSubNav ? <SubNav pageid={props.pageid} className="border-0" toggleBtn={() =>
                                    <button className="custom-toggler navbar-toggler external-link border-0" type="button" data-toggle="collapse" data-target="#sidebar-wrapper" aria-controls="sidebar-wrapper" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                        <i className="bi bi-three-dots"></i>
                                    </button>
                                } /> : props.additionalSubNav ? props.additionalSubNav() : <div id='subNav' className='empty'></div>
                            } */}
              <div className="side-content-body">
                <div className="row d-lg-none mb-3">
                  <div className="col-6">
                    {/* <Button variant={5} onClick={() => { setShowWrapper(true); }} className="btn-100 btn-withicon my-3" style={{ borderWidth: 1 }}>
                                            <span className='me-1'>メニュー</span>
                                            <FilterIcon style={{ height: 14 }} />
                                        </Button> */}
                  </div>
                  <div className="col-6">
                    {props.sortBy && (
                      <SortSelect
                        className="sort-sm my-3"
                        value={props.sortBy}
                        onChange={props.onChangeSort}
                      />
                    )}
                    {/* <Button variant={5} onClick={() => { }} className="btn-100 btn-withicon my-3" style={{ borderWidth: 1 }}>
                                            <span className='me-1'>新しい順</span>
                                            <SortIcon style={{ height: 14 }} />
                                        </Button> */}
                  </div>
                </div>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyPageWrapper;

const MyPageSidebar = (props) => {
  const navigate = useNavigate();
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const content = translations.myPageWrapper;
  const subtitleContent = translations.myPageWrapper.subtitle;
  const listContent = translations.myPageWrapper.list;

  const sidebar_items = [
    {
      id: "マイトークン",
      name: subtitleContent.myTokens,
      className: "group",
      to: "",
      items: [
        {
          id: "保有アルカナ",
          name: listContent.holdingArcana,
          icon: (
            <ArcanaIcon
              white='true'
              style={{ marginBottom: 6, width: 23, height: 23 }}
            />
          ),
          to: "/mypage/owned-arcana",
        },
        {
          id: "保有ペルソナ",
          name: "保有ペルソナ",
          icon: <PersonaIcon style={{ width: 22, height: 18.1 }} />,
          to: "/mypage/owned-persona",
        },
      ],
    },
    {
      id: "取引",
      name: subtitleContent.transaction,
      className: "group",
      to: "",
      items: [
        {
          id: "買い取引",
          name: listContent.buyTransactions,
          icon: <BuyIcon style={{ marginBottom: 6, width: 19, height: 25 }} />,
          to: "/mypage/buy-transactions",
        },
        {
          id: "売り取引",
          name: listContent.sellTransactions,
          icon: <SellIcon style={{ marginBottom: 6, width: 19, height: 25 }} />,
          to: "/mypage/sell-transactions",
        },
      ],
    },
    {
      id: "オファー",
      name: subtitleContent.offer,
      className: "group",
      to: "",
      items: [
        {
          id: "受信オファー",
          name: listContent.incomingOffers,
          icon: (
            <BidIcon style={{ marginBottom: 6, width: 23.1, height: 18.8 }} />
          ),
          to: "/mypage/receive-offer",
        },
        {
          id: "送信オファー",
          name: listContent.outgoingOffers,
          icon: (
            <AskIcon style={{ marginBottom: 6, width: 23.1, height: 18.8 }} />
          ),
          to: "/mypage/send-offer",
        },
      ],
    },
    {
      id: "保存",
      name: subtitleContent.save,
      className: "group",
      to: "",
      items: [
        {
          id: "ブックマーク",
          name: listContent.bookmark,
          icon: (
            <BookmarkIcon style={{ marginBottom: 5, width: 17, height: 19 }} />
          ),
          to: "/mypage/bookmarks",
        },
        {
          id: "閲覧履歴",
          name: listContent.browsingHistory,
          icon: (
            <HistoryIcon style={{ marginBottom: 6, width: 20, height: 20 }} />
          ),
          to: "/mypage/history",
        },
      ],
    },
    {
      id: "設定",
      name: subtitleContent.establishment,
      className: "group",
      to: "",
      items: [
        {
          id: "アカウント",
          name: listContent.account,
          icon: (
            <FunctionIcon style={{ marginBottom: 6, width: 24, height: 19 }} />
          ),
          to: "/mypage/account",
        },
        {
          id: "お支払い",
          name: listContent.receivingAccountInformation,
          icon: (
            <PaymentIcon
              style={{ marginBottom: 6, width: 23, height: 15.75 }}
            />
          ),
          to: "/mypage/payment",
        },
        {
          id: "機能設定",
          name: listContent.functionSettings,
          icon: (
            <SettingIcon style={{ marginBottom: 6, width: 24, height: 18 }} />
          ),
          to: "/mypage/setting",
        },
      ],
    },
  ];
  return (
    <ul className={`sidebar-nav pt-4 position-sticky ${props.className || ""}`}>
      <li className="sidebar-brand mb-2 d-flex justify-content-between align-items-center p-0 m-0">
        <a href="#" className="title">
          <h3 className="italic mb-0">{content.title}</h3>
        </a>
        <CloseIcon
          className="d-block d-lg-none"
          onClick={() => props.setShowWrapper((show) => !show)}
          style={{ stroke: "#FFF", width: "unset" }}
        />
      </li>
      {sidebar_items.map((item, index) => (
        <li
          key={index}
          className={`${item.className} ${
            item.id == props.pageid ? "active" : ""
          }`}
          id={item.id}
        >
          {item.items && item.items.length > 0 ? (
            <>
              <p>{item.name}</p>
              <ul>
                {item.items.map((subitem, subindex) => (
                  <li
                    key={`sub-${subindex}`}
                    className={subitem.id == props.pageid ? "active" : ""}
                  >
                    <Link
                      to={subitem.to}
                      className={"d-flex align-items-center subitem"}
                    >
                      <div>
                        {subitem.icon}
                        <span className="ms-2" style={{ fontSize: 18 }}>
                          {subitem.name}
                        </span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <Link to={item.to}>{item.name}</Link>
          )}
        </li>
      ))}
      <li className="last d-flex justify-content-between px-3">
        <a
          href="#"
          className={"d-flex align-items-center subitem"}
          onClick={() => setShowConfirmLogout(true)}
        >
          <div>
            <LogoutIcon style={{ marginBottom: 4, width: 20, height: 18 }} />
            <span className="ms-2">ログアウト</span>
          </div>
        </a>
        <AnicanaLogoImg style={{ width: 100, height: "auto" }} />
      </li>
      <ConfirmLogoutModal
        isOpen={showConfirmLogout}
        onRequestClose={() => setShowConfirmLogout(false)}
      ></ConfirmLogoutModal>
    </ul>
  );
};

const ConfirmLogoutModal = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const signout = async () => {
    setLoading(true);
    logout();
    setLoading(false);
    onRequestClose();
    logout(() => {
      window.location.href = "/";
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} lg>
      <div className="container-fluid selling-price-confirmation text-center">
        <h4 className="mb-5">{translations.textLogout}</h4>
        <div>本当にログアウトしてもよろしいですか？</div>
        <div className="row btns">
          <div className="col-6">
            <Button variant={2} onClick={onRequestClose} className="w-100">
              {translations.textCancel}
            </Button>
          </div>
          <div className="col-6">
            <Button
              variant={3}
              loading={loading}
              onClick={() => signout()}
              className="w-100"
            >
              {translations.textLogout}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
