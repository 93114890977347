import React, { useContext, useEffect, useState } from 'react'
import { formatMoney } from '../../components/commonFnc'
import { Button } from '../../components/Form'
import MyPageWrapper from '../../components/MyPageWrapper'
import RNDataTable from '../../components/RNDataTable'
import { getData, postData } from '../../services/fetch'
import moment from 'moment'
import Modal from '../../components/Modal'
import Pagination from '../../components/Pagination'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SubNav from '../../components/SubNav'
import { OrderType, TokenType, paymentCurrency } from '../../components/enum'
import Loading from '../../components/Loading'
import { CloseIcon } from '../../components/Icon'
import { Web3authContext } from '../../context/web3authContext'
import Swal from '../../components/Swal'
import { translations } from "../../services/localization";

const SendOffer = () => {
  const navigate = useNavigate();
  const { web3Approve, id_token, setId_token, web3Login, isRedirected, setIsRedirected, setProvider } = useContext(Web3authContext);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [cancelOffer, setCancelOffer] = useState({});
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const location = useLocation();
  const search = location.search;
  const query = new URLSearchParams(search);

  useEffect(() => {
    if (location.state && location.state.error) {
      Swal.fire({
        icon: 'error',
        title: 'エラーが発生しました',
        text: location.state.error,
      });
    }
    let id_token = query.get('id_token')
    setId_token(id_token);
    if (id_token) {
      console.log('redirect')
      setIsRedirected(true);
    }
  }, [location.state])

  // useEffect(() => {
  //   if (id_token && isRedirected) {
  //     web3Login(id_token);
  //   }
  // }, [id_token, isRedirected])

  const cancelToken = () => {
    setCancelLoading(true);
    if (cancelOffer.type == OrderType.Sell) {
      handleApprove();
    } else {
      cancel(cancelOffer.id);
    }
  }

  useEffect(() => {
    getSendOffers(page);

    return () => {
      setData([]);
      setCancelOffer({});
    }
  }, [page])

  const handleApprove = async () => {
    web3Approve(cancelOffer.token.token_type_id, cancelOffer.token.token_id, () => { cancel(cancelOffer.id) }, true);
  }

  const getSendOffers = async (page) => {
    setLoading(true);
    var response = await getData(`api/orders/get-sent-offers?page=${page}`, true);
    if (response.ok) {
      var data = JSON.parse(JSON.stringify(response.data.sent_offers.data));
      for (var i = 0; i < data.length; i++) {
        if (!data[i]["token"]) {
          // data[i]["token"] = await getTokenDetail(data[i].token_type_id, data[i].token_id);
          data[i]["token"] = {};
        }
      }
      console.log(data)
      setData(data);
      setPage(response.data.sent_offers.current_page);
      setLast_page(response.data.sent_offers.last_page);
    }
    setLoading(false);
  }

  const cancel = async (id) => {
    var response = await postData(`api/orders/${id}/cancel`);
    setCancelLoading(false);
    if (response.ok) {
      getSendOffers();
      setIsOpen(false);
      Swal.fire({
        title: 'Cancel Offer',
        text: 'オファーがキャンセルされました.'
      })
    }
    setCancelOffer({});
    //   setIsOpen(false);
    //   Swal.fire({
    //     title: 'Cancel Offer',
    //     text: 'オファーがキャンセルされました.'
    //   })
  }

  const OfferMobileView = props => {
    const { index, item } = props
    return (
      <div className={`d-flex flex-row p-2`} >
        <div className='me-2'>
          <img src={item.token.image} className='h-100' style={{ width: 100, objectFit: 'cover' }} />
        </div>
        <div className='d-flex flex-column w-100' style={{ gap: 4 }}>
          <div className='d-flex justify-content-between'>
            <div className='text-overflow fw-600' style={{ maxWidth: 100 }}>{item.token.name}</div>
            <div className='fw-400' style={{ maxWidth: 200, color: "#E88B00" }}>{item.status == "cancelled" ? <span className='td-status'>キャンセル済み</span> :
              item.status == "matched" || item.status == "traded" ?
                item.status == "matched" ?
                  <span>取引中</span>
                  :
                  <span>取引完了</span>
                // < Button variant={2} onClick={() => { navigate(`/mypage/trade/detail/${row.trade?.id}`) }} className="w-100">{'取引詳細'}</Button>
                :
                item.status == "active" ?
                  <span className='td-status text-reset'>注文中</span>
                  :
                  item.status == "nonactive" ?
                    item.type == "sell" ? <span>確認中</span> : <span className='td-status  text-reset'>決済待ち</span>
                    : ''

              // {/* <Button variant={3} onClick={() => { setCancelOffer(item); setIsOpen(true); }} className="w-100 min-w-auto">{'キャンセル'}</Button> */}
            }</div>
          </div>
          <div className='d-flex justify-content-between'>
            <div className='fw-300' style={{ fontSize: 14 }}>取引価格</div>
            <div className='fw-600'>{formatMoney(
              (Number(item.currency) === paymentCurrency.Anima
                ? item.token?.anm?.bid
                : item.token?.jpy?.bid) || 0,
              item.currency
            )}</div>
          </div>
          <div className='d-flex justify-content-between'>
            <div className='fw-300' style={{ fontSize: 14 }}>落札日</div>
            <div className='fw-300' style={{ fontSize: 14 }}>{moment(item.created_at).format("yyyy/MM/DD")}</div>
          </div>
          <div className='d-flex justify-content-end mt-2'>
            <div>{item.status == "cancelled" ? '' :
              item.status == "matched" || item.status == "traded" ?
                <div>
                  <Button variant={2} onClick={() => { navigate(`/mypage/trade/detail/${item.trade?.id}`) }} className="w-100 px-2 py-1">{'取引詳細'}</Button>
                </div>
                :
                <div>
                  <Button variant={3} onClick={() => { setCancelOffer(item); setIsOpen(true); }} className="w-100 min-w-auto px-2" style={{paddingTop: '1px', paddingBottom: '1px'}}>{'キャンセル'}</Button>
                </div>
            }</div>
          </div>
        </div>
      </div >
    );
  }

  const columns = [
    {
      name: 'イベント',
      selector: row => row.type === OrderType.Buy ? "買い" : "売り",
      center: true,
    },
    {
      name: 'トークン',
      selector: row => row.token_id,
      cell: row =>
        <div className='d-flex align-items-center token-cell'>
          <Link to={`/detail/${row.token_type_id}/${row.token_id}`}><img src={row.token.image} /></Link>
          <div className='d-flex flex-column'>
            <Link className='mb-2 name text-reset' to={`/detail/${row.token_type_id}/${row.token_id}`}>{row.token.name}</Link>
          </div>
        </div>,
      center: true,
      width: '250px',
      style: {
        justifyContent: 'flex-start',
      },
    },
    //
    // {
    //   name: 'SP',
    //   right: true,
    // },
    {
      name: '金額',
      selector: row => formatMoney(row.amount, row.currency),
      right: true,
    },
    {
      name: '送信日時',
      selector: row => moment(row.created_at).format("yyyy/MM/DD"),
      right: true,
    },
    {
      name: '現在価格',
      selector: row =>
        formatMoney(
          (Number(row.currency) === paymentCurrency.Anima
            ? row.token?.anm?.bid
            : row.token?.jpy?.bid) || 0,
          row.currency
        ),
      right: true,
    },
    {
      name: 'ステータス', // 'キャンセル',
      selector: row => row.status == "cancelled" ? <span className='td-status'>キャンセル済み</span> :
        row.status == "matched" || row.status == "traded" ?
          <div className='contract'>
            {row.status == "matched" ?
              <span className='td-status'>取引中</span>
              :
              <span className='td-status'>取引完了</span>
            }
            <Button variant={2} onClick={() => { navigate(`/mypage/trade/detail/${row.trade?.id}`) }} className="w-100">{'取引詳細'}</Button>
          </div> :
          <div className='contract'>
            {row.status == "active" ?
              <span className='td-status text-reset'>注文中</span> :
              row.status == "nonactive" ?
                (row.type == "sell" ? <span className='td-status  text-reset'>確認中</span> : <span className='td-status  text-reset'>決済待ち</span>) : <></>
            }
            <Button variant={3} onClick={() => { setCancelOffer(row); setIsOpen(true); }} className="w-100 min-w-auto">{'キャンセル'}</Button>
          </div>,
      center: true,
      minWidth: '150px',
    }
  ];
  return (
    <MyPageWrapper pageid='送信オファー'>
        <SubNav pageid="送信オファー" items={[
          {
            id: '受信オファー',
            name: translations.myPageWrapper.list.incomingOffers,
            to: '/mypage/receive-offer',
          },
          {
            id: '送信オファー',
            name: translations.myPageWrapper.list.outgoingOffers,
            to: '/mypage/send-offer',
          },
        ]} />
      
      <div className='d-flex flex-row align-items-end mb-3 d-none d-sm-block'>
        <h3 className='text-start'>{translations.myPageWrapper.list.outgoingOffers}</h3>
      </div>
      {
        loading ? <Loading /> :
          <>
            {/* PC View */}
            <RNDataTable
              className='d-none d-sm-block'
              columns={columns}
              data={data}
              noDataComponent={<span style={{ marginTop: 50 }}>{translations.emptylist_messages.noOrderHistory}</span>}
            />
            {/* End PC View */}

            {/* Mobile View */}
            <div className='row d-sm-none'>
              {
                data.map((item, index) =>
                  <div key={index} className={"col-12"} style={{ backgroundColor: index % 2 == 0 ? '#EDF1FB' : '' }}>
                    <OfferMobileView
                      item={item}
                      index={index}
                    />
                  </div>
                )
              }
            </div>
            {/* End Mobile View */}

            <Pagination onPageChange={page => setPage(page)} page={page} last_page={last_page} />
          </>
      }
      <Modal isOpen={isOpen} onRequestClose={() => { setIsOpen(false) }}>
        <CloseIcon onClick={() => { setIsOpen(false) }} className="position-absolute" style={{ width: 26, height: 26, right: 0, stroke: '#FFF' }} />
        <p className='h3 fw-400 mb-5 text-center'>オファーキャンセル</p>
        <div className='row mb-3'>
          <div className='col-4'>
            <span className='fw-bold'>取引内容</span>
          </div>
          <div className='col-8 text-end'>
            <span className='break-word fw-400'>{cancelOffer.token_type_id == TokenType.Arcana ? "アルカナ" : "ペルソナ"}</span>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-4'>
            <span className='fw-bold'>トークン名</span>
          </div>
          <div className='col-8 text-end'>
            <span className='break-word fw-400'>{cancelOffer.token ? cancelOffer.token.name : ""}</span>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-4'>
            <span className='fw-bold'>トークンid</span>
          </div>
          <div className='col-8 text-end'>
            <span className='break-word fw-400'>{cancelOffer.token_id}</span>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-4'>
            <span className='fw-bold'>取引希望金額</span>
          </div>
          <div className='col-8 text-end'>
            <span>{formatMoney(cancelOffer.amount, cancelOffer.currency)}</span>
          </div>
        </div>
        <p className='fw-400 text-center mt-5'>こちらのオファーをキャンセルします。</p>
        <p className='fw-400 text-center mb-5'>よろしいですか？</p>
        <div className={'row'}>
          {/* <Button variant={5} onClick={()=>setIsOpen(false)} className="col-4 float-center ">{'閉じる'}</Button> */}
          <Button variant={3} loading={cancelLoading} onClick={() => cancelToken()} className="col-4 float-center">
            {'実行'}
          </Button>
        </div>
      </Modal>
    </MyPageWrapper>
  )
}

export default SendOffer


