import { clearLocalStorage, getToken } from "./storage";
import axios from "axios";
import { REACT_APP_API_URL } from "../config";
import Swal from "../components/Swal";
import { history } from "../auth/history";
// import base64 from 'base-64';

const api_url = REACT_APP_API_URL;

const axiosClient = axios.create({
  baseURL: api_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.response.use(
  function (response) {
    response.ok = true;
    return response;
  },
  function (error) {
    let res = error.response;
    console.log("fetch error", error);
    if (res.status == 401) {
      // logout(() => window.location.href = "/login");
      console.log(res.status);
      history.push("/logout?returnURL=/login");
      return false;
    } else {
      console.error(`エラーが発生しました [${res.status}]`, res);
      if (res && res.data && res.data.message) {
        Swal.fire({
          icon: "error",
          title: "エラーが発生しました",
          text: res.data.message || res.data,
        });
      }
      res.ok = false;
      return res;
    }
  }
);

export async function getData(url = "", withCredentials = false) {
  var token = getToken();
  return await axiosClient({
    method: "get",
    url,
    withCredentials,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export async function postData(url = "", data = {}) {
  return await fetchData(url, data);
}

export async function putData(url = "", data = {}) {
  return await fetchData(url, data, "put");
}

export async function deleteData(url = "", data = {}) {
  return await fetchData(url, data, "delete");
}

export async function postFormData(url = "", data = {}) {
  var token = getToken();
  return await axiosClient({
    method: "post",
    url,
    withCredentials: true,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: toFormData(data),
  });
}

export async function fetchData(
  url = "",
  data = {},
  method = "post",
  withCredentials = false,
  customToken = undefined
) {
  var token = getToken();
  return await axiosClient({
    method,
    url,
    withCredentials: true,
    headers: {
      Authorization:
        "Bearer " + (customToken === undefined ? token : customToken),
    },
    data,
  });
}

export const logout = async (fnc = undefined) => {
  var response = await postData(`api/logout`);
  ClearCredentials(fnc);
  // if (response.ok) {
  //   setToken("");
  //   setWalletAddress("");
  //   if(fnc) {
  //     fnc();
  //   }
  // } else {
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'エラーが発生しました',
  //     text: response.data,
  //   })
  // }
};

export const ClearCredentials = (fnc = undefined) => {
  clearLocalStorage();

  if (fnc) {
    fnc();
  }
};

export const handleError = (error) => {
  var err = "";
  const { errors, message } = error;
  if (errors && errors.length > 0) {
    for (var i = 0; i < errors.length; i++) {
      err += (i === 0 ? "" : ", ") + errors[i].message;
    }
  } else if (message) {
    err = message;
  }
  Swal.fire({
    icon: "error",
    title: "エラーが発生しました",
    text: err,
  });
};

export const toFormData = (item) => {
  var form_data = new FormData();
  // form_data.append('debug_mode', 'true');
  for (var key in item) {
    var value = item[key];
    console.log(key, value);
    form_data.append(`${key}`, value);
  }
  console.log(form_data);
  return form_data;
};
