/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatNumber, formatMoney, handleBookmark } from "./commonFnc";
import { Button } from "./Form";
import { Carousel } from "react-responsive-carousel";
import MultiCarousel from "react-multi-carousel";
import { ArcanaIcon, BookmarkIcon } from "./Icon";
import BorderGradient from "./Border";
import { TokenType } from "./enum";
import { ReactComponent as DefaultUserImg } from "../assets/images/default_user.svg";
import { ReactComponent as ArcanaSVG } from "../assets/icons/arcana_icon.svg";
import { ReactComponent as PersonaSVG } from "../assets/icons/persona_icon.svg";
import "../assets/scss/item.scss";
import { translations } from "../services/localization";

const Item = (props) => {
  const item = props.item || {};
  const index = props?.index;
  const [is_bookmarked, setIs_bookmarked] = useState(item.is_bookmarked);
  const formatter = (n) => n ? formatNumber(n) : '---'

  return (
    <div className={`mb-3 item`} style={{ position: "relative" }}>
      <a
        className="nav-link"
        style={{
          zIndex: 2,
          position: "absolute",
          top: 3,
          right: index % 2 !== 0 ? 10 : 5,
        }}
        onClick={() =>
          handleBookmark(item.token_id, item.token_type_id, () => {
            setIs_bookmarked((is_bookmarked) => !is_bookmarked);
          })
        }
        aria-current="page"
      >
        <BookmarkIcon is_bookmarked={is_bookmarked ? 1 : 0} style={{ width: 24, height: 27 }} />
      </a>
      <div
        className="pointer text-decoration-none"
        style={{ zIndex: 1 }}
        onClick={props.onClickBtn}
      >
        <div className="">
          <div className="gradient">
            <div className="card item-card item-min-height">
              <div className="d-flex flex-row justify-content-between">
                <div className="item-tag">
                  {item.new && <span className="tag">New</span>}
                </div>
              </div>
              <div className="card-image">
                {item.ipfs_image && (
                  <img
                    className="card-image pointer"
                    src={item.ipfs_image}
                    alt="nft"
                  />
                )}
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="text-start pointer fw-300 text-overflow line-clamp">
                    {/* {item.creator
                      ? item.creator.length === 42
                        ? `Token ID: ${item.token_id}`
                        : item.creator
                      : ""} */}
                    {item.token_type_id === TokenType.Arcana ? `Token ID: ${item.token_id}` : (item.creator || '')}
                  </div>
                  <div className="">
                    {item.token_type_id === TokenType.Arcana ? (
                      <img
                        src={require("../assets/images/arcana_white.png")}
                        alt=""
                        className="h-auto"
                        style={{ width: 27 }}
                      />
                    ) : (
                      <img
                        src={require("../assets/images/persona_white.png")}
                        alt=""
                        className="h-auto"
                        style={{ width: 35 }}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ height: 27 }}
                >
                  {/* <div className='profile-pic me-2'>
                    <DefaultUserImg />
                  </div> */}
                  <div className="fw-500 item-name-overflow adjust">
                    {item.name || ''}
                  </div>
                </div>
                <div className="position-absolute w-100">
                  <div className="d-sm-flex flex-row justify-content-between">
                    <div className="d-flex flex-row justify-content-between attribute px-0 px-lg-1" style={{ width: '100%' }}>
                      <div className="d-flex flex-column">
                        <div
                          className="mb-0 fw-300 me-4"
                          style={{ visibility: "hidden" }}
                        >
                          NO
                        </div>
                        <div className="mb-0 fw-300 me-4">ASK</div>
                        <div className="mb-0 fw-300 me-4">BID</div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="mb-0 fw-300 me-4">JPY</div>
                        <div className="mb-0 fw-300 me-4">
                          {formatter(item.jpy?.ask)}
                        </div>
                        <div className="mb-0 fw-300 me-4">
                          {formatter(item.jpy?.bid)}
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="mb-0 fw-300 me-4">ANM</div>
                        <div className="mb-0 fw-300 me-4">
                          {formatter(item.anm?.ask)}
                        </div>
                        <div className="mb-0 fw-300 me-4">
                          {formatter(item.anm?.bid)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;

export const SearchItem = (props) => {
  const item = props.item || {};
  const [is_bookmarked, setIs_bookmarked] = useState(item.is_bookmarked);
  // const [onMouseMove, setOnMouseMove] = useState(false);
  // const [onMouseDown, setOnMouseDown] = useState(false);
  const onClickBtn = () => {
    // if (props.handleMouseMove) {
    //   if (!onMouseMove) {
    //     props.onClickBtn();
    //   }
    //   setOnMouseMove(false);
    //   setOnMouseDown(false);
    // } else {
    //   props.onClickBtn();
    // }
    props.onClickBtn();
  };
  const formatter = (n) => n ? formatNumber(n) : '---'

  return (
    <div className="Card_container mb-3 bg_image">
      <a
        className="nav-link"
        style={{
          zIndex: 11,
          position: "absolute",
          top: 3,
          right: 5,
        }}
        onClick={() =>
          handleBookmark(item.token_id, item.token_type_id, () => {
            setIs_bookmarked((is_bookmarked) => !is_bookmarked);
          })
        }
        aria-current="page"
      >
        <BookmarkIcon is_bookmarked={is_bookmarked ? 1 : 0} style={{ width: 24, height: 27 }} />
      </a>
      <div
        onClick={onClickBtn}
        style={{ position: "relative", zIndex: 10 }}
        className="gradient bg_image"
      >
        {/* image contaienr */}
        <div className="img_container">
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={item.ipfs_image}
            alt="nft"
          />
        </div>
        {/* Card body */}
        <div style={{ paddingLeft: 7, paddingRight: 7, paddingBottom: 7 }}>
          {/* card header */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="card_header">
              {item.creator
                ? item.creator.length == 42
                  ? `Token ID: ${item.token_id}`
                  : item.creator
                : ""}
            </div>
            <div className="d-sm-none pt-1">
              {item.token_type_id == TokenType.Arcana ? (
                <ArcanaIcon white className="h-auto" style={{ width: 27 }} />
              ) : (
                <PersonaSVG className="h-auto" style={{ width: 35 }} />
              )}
            </div>
          </div>
          {/* card creator */}
          <div style={{ marginTop: 0 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: 40,
              }}
            >
              <div className="d-none d-sm-block">
                <DefaultUserImg />
              </div>
              <p className="card_creator">{item.name}</p>
            </div>
          </div>
          {/* card detail */}
          <div
            className="d-none d-sm-block"
            style={{
              width: "100%",
              height: "100%",
              marginTop: 9,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "yellowgreen",
              }}
            >
              <div className="d-flex flex-row justify-content-between attribute px-0 px-lg-1">
                <div className="d-flex flex-column">
                  <div
                    className="mb-0 fw-300 me-3"
                    style={{ visibility: "hidden" }}
                  >
                    NO
                  </div>
                  <div className="mb-0 fw-300 me-3">ASK</div>
                  <div className="mb-0 fw-300 me-3">BID</div>
                </div>
                <div className="d-flex flex-column">
                  <div className="mb-0 fw-300 me-3">JPY</div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.jpy?.ask)}
                  </div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.jpy?.bid)}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="mb-0 fw-300 me-3">ANM</div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.anm?.ask)}
                  </div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.anm?.bid)}
                  </div>
                </div>
              </div>

              <div className="d-none d-sm-block" style={{ marginTop: 9 }}>
                {item.token_type_id == TokenType.Arcana ? (
                  <Button className="p0" variant={2}>
                    {translations.arcana}
                  </Button>
                ) : (
                  <Button className="p0">{translations.persona}</Button>
                )}
              </div>
            </div>
          </div>
          {/* small */}
          <div
            className="d-block d-sm-none"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="d-flex flex-row justify-content-between attribute px-0 px-lg-1">
                <div className="d-flex flex-column">
                  <div
                    className="mb-0 fw-300 me-3"
                    style={{ visibility: "hidden" }}
                  >
                    NO
                  </div>
                  <div className="mb-0 fw-300 me-3">ASK</div>
                  <div className="mb-0 fw-300 me-3">BID</div>
                </div>
                <div className="d-flex flex-column">
                  <div className="mb-0 fw-300 me-3">JPY</div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.jpy?.ask)}
                  </div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.jpy?.bid)}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="mb-0 fw-300 me-3">ANM</div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.anm?.ask)}
                  </div>
                  <div className="mb-0 fw-300 me-3">
                    {formatter(item.anm?.bid)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CategoryCarousel = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <h2 className="title text-uppercase text-center" style={{ height: 56 }}>
          <span className="icon" style={{ marginRight: 12 }}>
            {props.icon}
          </span>
          {props.title}
        </h2>
        <Carousel
          className={` indicator-below ${props.comingsoon && "disabled"}`}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          renderIndicator={false}
        >
          {props.images &&
            props.images.map((image, index) => (
              <div key={index} onClick={props.onClick}>
                <div className="gradient">
                  <div className={`category-carousel`}>
                    {/* {image} */}
                    <img src={image} className="d-flex w-100" />
                    {props.comingsoon && (
                      <div className="coming-soon">
                        <span>Coming Soon</span>
                      </div>
                    )}
                  </div>
                  {/* <img src={image} className='d-flex w-100' /> */}
                </div>
                <p>&nbsp;</p>
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export const ItemCarousel = (props) => {
  const navigate = useNavigate();
  return (
    <div className="row mx-0">
      <div className="col-12 px-0">
        <h3 className="title text-uppercase text-center">{props.title}</h3>
        <MultiCarousel
          responsive={responsive}
          showDots={false}
          arrows={false}
          swipeable={true}
          draggable={true}
          partialVisbile={true}
          itemClass="pe-3"
        >
          {props.items &&
            props.items.map((item, index) => (
              <Item
                key={index}
                item={item}
                handleMouseMove
                onClickBtn={() =>
                  navigate(`/detail/${item.token_type_id}/${item.token_id}`)
                }
              />
            ))}
          {console.log("conbined", props.items)}
        </MultiCarousel>
      </div>
    </div>
  );
};

// TODO:: No used item
export const NewBookmarkItem = (props) => {
  const navigate = useNavigate();
  const item = props.item || {};
  const [is_bookmarked, setIs_bookmarked] = useState(item.token.is_bookmarked);
  return (
    <Link to={`/detail/${item.token_type_id}/${item.token_id}`}>
      <div className="mb-3 item" style={{ position: "relative" }}>
        <a
          className="nav-link"
          style={{
            zIndex: 2,
            position: "absolute",
            top: 10,
            right: 15,
          }}
          onClick={() =>
            handleBookmark(item.token_id, item.token_type_id, () => {
              setIs_bookmarked((is_bookmarked) => !is_bookmarked);
            })
          }
          aria-current="page"
        >
          <BookmarkIcon is_bookmarked={is_bookmarked ? 1 : 0} />
        </a>
        <div className="pointer text-decoration-none" style={{ zIndex: 1 }}>
          <div className="">
            <div className="gradient">
              <div className="card item-card">
                <div className="card-image">
                  {item.ipfs_image && (
                    <img className="card-image pointer" src={item.ipfs_image} />
                  )}
                </div>
                <div className="card-body">
                  <span className="card-title text-start pointer fw-300">
                    {item.name}
                  </span>
                  <div className="d-flex flex-row align-items-center">
                    {/* <div className='profile-pic me-3'>
                    <DefaultUserImg />
                  </div> */}
                    <h5 className="text-overflow fw-700 my-3">
                      {item.creator}
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-7">
                      <div className="d-flex flex-row align-items-end">
                        <p
                          className="text-start mb-0 fw-300"
                          style={{ width: 40, fontSize: 15 }}
                        >
                          ASK
                        </p>
                        <h5 className="mb-0 fw-500">
                          {item.ask_price > 0
                            ? formatMoney(item.ask_price)
                            : "No price"}
                        </h5>
                      </div>
                      <div className="d-flex flex-row align-items-end">
                        <p
                          className="text-start mb-0 fw-300"
                          style={{ width: 40, fontSize: 15 }}
                        >
                          BID
                        </p>
                        <h5 className="mb-0 fw-500">
                          {item.bid_price > 0
                            ? formatMoney(item.bid_price)
                            : "No price"}
                        </h5>
                      </div>
                    </div>
                    <div className="col-5 mt-xs-3 ps-0 pt-2 d-flex align-items-center justify-content-end">
                      {item.token_type_id == TokenType.Arcana ? (
                        <ArcanaIcon
                          white="true"
                          className="h-auto"
                          style={{ width: 37 }}
                        />
                      ) : (
                        <PersonaSVG className="h-auto" style={{ width: 42 }} />
                      )}
                      {/* <Button variant={item.token_type_id == TokenType.Arcana ? "2" : item.token_type_id == TokenType.Persona ? "" : ""} onMouseDown={e => setOnMouseDown(true)} onMouseMove={e => { if (onMouseDown) { setOnMouseMove(true) } }} onClick={onClickBtn} className="float-end btn-reset text-uppercase btn-bold">{item.token_type_id == TokenType.Arcana ? "ARCANA" : item.token_type_id == TokenType.Persona ? "PERSONA" : props.buttonText}</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

// TODO:: No used item
export const BookmarkItem = (props) => {
  const navigate = useNavigate();
  const item = props.item || {};
  const [is_bookmarked, setIs_bookmarked] = useState(item.token.is_bookmarked);
  return (
    <div className="d-flex flex-md-row flex-column bookmark-item">
      <div className="me-md-3 mb-3 mb-lg-0">
        <Link to={`/detail/${item.token_type_id}/${item.token_id}`}>
          <img src={item.token.image} />
        </Link>
      </div>
      <div
        className="d-flex flex-column align-items-start w-100"
        style={{ minWidth: 0 }}
      >
        <div className="d-flex flex-column justify-content-between w-100">
          <div className="d-flex justify-content-between w-100">
            <div>
              <Link
                to={`/detail/${item.token_type_id}/${item.token_id}`}
                className="text-reset text-decoration-none"
              >
                <span className="text-break">{item.token.name}</span>
                <span className="ms-4 tag">
                  {item.token_type_id == TokenType.Arcana
                    ? "アルカナ"
                    : "ペルソナ"}
                </span>
              </Link>
            </div>
            <a
              className="nav-link"
              onClick={() =>
                handleBookmark(item.token_id, item.token_type_id, () => {
                  setIs_bookmarked((is_bookmarked) => !is_bookmarked);
                })
              }
              aria-current="page"
            >
              <BookmarkIcon
                is_bookmarked={is_bookmarked ? 1 : 0}
                className="ms-3"
              />
            </a>
          </div>
          <span className="text-overflow">{item.token.creator}</span>
        </div>
        <div className="d-flex flex-row mt-3">
          <p className="text-start mb-0" style={{ width: 50 }}>
            ASK
          </p>
          <p className="mb-0">
            <b>
              {item.token.ask_price > 0
                ? formatMoney(item.token.ask_price)
                : "No price"}
            </b>
          </p>
        </div>
        <div className="d-flex flex-row">
          <p className="text-start mb-0" style={{ width: 50 }}>
            BID
          </p>
          <p className="mb-0">
            <b>
              {item.token.bid_price > 0
                ? formatMoney(item.token.bid_price)
                : "No price"}
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export const responsive = {
  // xxxl: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 6000, min: 3000 },
  //   items: 8,
  //   partialVisibilityGutter: 30
  // },
  // xxxlsm: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 3000, min: 2500 },
  //   items: 6,
  //   partialVisibilityGutter: 30
  // },
  xxl: {
    // the naming can be any, depends on you.
    breakpoint: { max: 6000, min: 1500 },
    items: 4,
    partialVisibilityGutter: 20,
  },
  xxlsm: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1500, min: 1400 },
    items: 3,
    partialVisibilityGutter: 100,
  },
  xl: {
    breakpoint: { max: 1400, min: 1200 },
    items: 3,
    partialVisibilityGutter: 50,
  },
  lg: {
    breakpoint: { max: 1200, min: 1000 },
    items: 2,
    partialVisibilityGutter: 150,
  },
  slg: {
    breakpoint: { max: 1000, min: 992 },
    items: 3,
    partialVisibilityGutter: 10,
  },
  md: {
    breakpoint: { max: 992, min: 768 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  sm: {
    breakpoint: { max: 768, min: 576 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  xs: {
    breakpoint: { max: 576, min: 568 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  xxs: {
    breakpoint: { max: 568, min: 375 },
    items: 1,
    partialVisibilityGutter: 50,
  },
  xxxs: {
    breakpoint: { max: 375, min: 0 },
    items: 1,
    partialVisibilityGutter: 20,
  },
};

export const TotalBid = () => {
  return (
    <div className={`amount-box-container`} style={{ maxWidth: 520 }}>
      <div>{Number(16000)} JPY</div>
      <div>{Number(1200)} ANM</div>
      <div className={`amount-box-title`}>Total Bid</div>
    </div>
  )
}
