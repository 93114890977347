import React from 'react'
import DataTable from 'react-data-table-component'
import { color } from '../assets/style';

const customStyles = {
    table: {
        style: {
            maxWidth: 1300,
            fontSize: 20,
        }
    },
    rows: {
        style: {

        },
        stripedStyle: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#EDF1FB',
            },
        },
    },
    headCells: {
        style: {
            // fontWeight: 'bold',
            fontSize: 15,
            fontWeight: 'normal',
            borderBottom: '1px solid #999999',
            width: 'auto'
        },
    },
    cells: {
        style: {
            fontSize: 15,
            padding: '8px 16px',
            color: color.primary,
        },
    },

};

const RNDataTable = (props) => {
    return (
        <DataTable
            {...props}
            customStyles={Object.assign(customStyles, props.customStyles)}
            striped
        />
    )
}

export default RNDataTable