import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Form'
import { CameraIcon, ChevronRight2Icon, EditIcon } from '../../components/Icon';
import MyPageWrapper from '../../components/MyPageWrapper'
import { getData, postData, postFormData, logout } from '../../services/fetch';
import { ReactComponent as DefaultUserImg } from '../../assets/images/default_user_color.svg';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal'
import { copyToClipboard } from '../../components/commonFnc';
import { CopyIcon } from '../../components/Icon';
import { translations } from "../../services/localization";

const Account = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(undefined);
    const [profilePic, setProfilePic] = useState(undefined);
    const [showConfirmWalletDisconnect, setShowConfirmWalletDisconnect] = useState(false);
    const [showConfirmUploadPhoto, setShowConfirmUploadPhoto] = useState(false);
    const fileRef = useRef();
    const content = translations.account;
    const contentLabel = translations.account.label;

    useEffect(() => {
        getAccountInfo();

        return () => {
            clear();
        }
    }, []);

    const getAccountInfo = async () => {

        var response = await getData(`api/users/user-detail`);
        if (response.ok) {
            setUser(response.data.user);
            console.log(user)
        }
    }

    const clear = () => {
        setUser(undefined);
    }

    const handleCameraClick = () => fileRef.current.click();
    const handleFileChange = e => setProfilePic(e.target.files[0]);
    const handleDisconnectWalletModalClose = () => setShowConfirmWalletDisconnect(false)
    const handleUploadPhotoModalClose = () => setShowConfirmUploadPhoto(false)
    const updateProfilePic = async (profilePic) => {
        console.log(profilePic)
        var response = await postFormData(`api/users/update-profile-image`, {
            image_url: profilePic
        });
        if (response.ok) {
            console.log(response)
            // setUser(response.data.user);
            getAccountInfo()
        }
    }

    useEffect(() => {
        if (profilePic) {
            setShowConfirmUploadPhoto(true)
            // updateProfilePic(profilePic);
        }
    }, [profilePic])

    return (
        <MyPageWrapper disabledSubNav pageid='アカウント'>
            {
                user ?
                    <div className='container account'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row mb-5'>
                                    <div className='' style={{ width: 190 }}>
                                        <div className='position-relative' style={{ width: 140 }}>
                                            {
                                                user.profileImageUrl ?
                                                    <img className='profile-pic' src={user.profileImageUrl} style={{ objectFit: 'cover', objectPosition: 'center' }} /> :
                                                    <DefaultUserImg />
                                            }
                                            <div className='camera-circle pointer' onClick={handleCameraClick}>
                                                <CameraIcon style={{ fill: '#FFF' }} />
                                                <input type='file' ref={fileRef} hidden onChange={handleFileChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col d-flex align-items-center'>
                                        <h3 className='me-3'>{user.accountName}</h3>
                                        <EditIcon className="pointer" onClick={() => navigate('/mypage/account/info/accountName', { state: { account_name: user.accountName } })} />
                                    </div>
                                </div>
                                <WalletDesc label={contentLabel.walletAddress} value={user.wallet_address} onDisconnectClick={() => { setShowConfirmWalletDisconnect(true) }} />
                                <Desc label={contentLabel.userNo} value={user.id} />
                                <Desc label={contentLabel.emailAddress} value={user.email} onClick={() => navigate("/mypage/account/info/email", { state: { email: user.email } })} />
                                <Desc label={contentLabel.phoneNumber} value={user.mobileNumber} onClick={() => navigate("/mypage/account/info/mobile", { state: { mobile: user.mobileNumber } })} />
                                {/* <Desc label="ランク" value={"Gold"} /> */}
                                {/* <Desc label="姓" value={user.last_name} onClick={() => navigate("/mypage/account/info/name", { state: { first_name: user.first_name, last_name: user.last_name } })} />
                                <Desc label="名" value={user.first_name} onClick={() => navigate("/mypage/account/info/name", { state: { first_name: user.first_name, last_name: user.last_name } })} /> */}
                                <Desc label={contentLabel.password} value={"パスワードを変更する"} onClick={() => navigate("/mypage/account/password")} />
                                {/* <Desc label="本人確認" value={"本人確認をする"} onClick={() => navigate("/mypage/account/kyc")} valueClass={'text-danger'} /> */}
                                <br />
                                <Button variant={3} onClick={() => navigate("/mypage/account/delete")} className="">{content.buttonAccountDelete}</Button>
                                {/* <a className='my-5 text-reset text-decoration-none fw-bold' onClick={() => navigate("/mypage/account/delete")}><span>退会する</span></a> */}
                            </div>
                        </div>
                    </div> : <></>
            }

            <ConfirmDisconnectWalletModal isOpen={showConfirmWalletDisconnect} onRequestClose={handleDisconnectWalletModalClose}></ConfirmDisconnectWalletModal>
            <ConfirmUploadPhotoModal isOpen={showConfirmUploadPhoto} onRequestClose={handleUploadPhotoModalClose} profilePic={profilePic} getAccountInfo={getAccountInfo}></ConfirmUploadPhotoModal>
        </MyPageWrapper>
    )
}

export default Account

const Desc = props => {
    const { label, value, onClick, labelClass, valueClass } = props;
    return (
        <div className='row mb-4'>
            <div className='' style={{ width: 300 }}>
                <span className={`fw-bold ${labelClass}`}>{label}</span>
            </div>
            <div className='col'>
                <a onClick={onClick}>
                    <div className='row'>
                        <div className='col d-flex align-items-center'>
                            <span className={`${valueClass || ''} fw-500`} style={{ minWidth: 250 }}>{value}</span>
                            {onClick && <ChevronRight2Icon className={`ms-5 ${valueClass}`} style={{ width: 12, height: 12 }} />}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}


const WalletDesc = props => {
    const { label, value, onClick, labelClass, valueClass, onDisconnectClick } = props;
    return (
        <div className='row mb-2'>
            <div className='' style={{ width: 300 }}>
                <span className={`fw-bold ${labelClass}`}>{label}</span>
            </div>
            <div className='col-12 col-sm-6' >
                <div className='d-flex flex-row' style={{ gap: 10 }}>
                    <div className='position-relative w-100'>
                        <input className="form-control" type="text" value={value} style={{ border: "1px solid" }}></input>
                        <CopyIcon onClick={() => copyToClipboard(value)} className="pointer position-absolute" style={{ paddingLeft: 2, width: 30, right: 10, bottom: 10, backgroundColor: 'white' }} />
                    </div>
                    <Button style={{width: 80}} variant={3} onClick={() => { onDisconnectClick() }}> 切断 </Button>
                    {/* <Button variant={3} onClick={() => { onDisconnectClick() }}>Disconnect</Button> */}
                    {/* <span
                        className={`${valueClass || ''} fw-500 ml-5 `}
                        style={{ minWidth: 250, border: "1px solid", borderRadius: "10px", padding: "10px 17px" }}>{value}
                        <CopyIcon onClick={() => copyToClipboard(value)} className="pointer" />
                    </span> */}

                </div>
            </div>
            {/* <div className='col-lg-3 col-sm-12 col-md-12'>
            </div> */}
        </div>
    )
}

const ConfirmDisconnectWalletModal = ({ isOpen, onRequestClose }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const disconnect = async () => {
        setLoading(true);
        await postFormData(`api/users/delete-wallet-address`);
        setLoading(false);
        onRequestClose();
        logout(() => { window.location.href = "/" });
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} lg>
            <div className='container-fluid selling-price-confirmation text-center'>
                <h4 className='mb-5'>ウォレット切断</h4>
                <div>
                    ANICANA Walletを切断するとOctillionの<br />
                    サービスを利用できなくなります。<br />
                    切断してもよろしいですか？
                </div>
                <div className='row btns'>
                    <div className='col-6'>
                        <Button variant={2} onClick={onRequestClose} className="w-100">{'キャンセル'}</Button>
                    </div>
                    <div className='col-6'>
                        <Button variant={3} loading={loading} onClick={() => disconnect()} className="w-100">{'実行'}</Button>
                    </div>
                </div>
            </div >
        </Modal>
    )
}

const ConfirmUploadPhotoModal = ({ isOpen, onRequestClose, profilePic, getAccountInfo }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const disconnect = async () => {
        setLoading(true);
        var response = await postFormData(`api/users/update-profile-image`, {
            image_url: profilePic
        });
        if (response.ok) {
            await getAccountInfo()
        }
        setLoading(false);
        onRequestClose();
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} lg>
            <div className='container-fluid selling-price-confirmation text-center'>
                <h4 className='mb-5'> 画像アップロード</h4>
                <div>
                プロフィール画像を変更します。よろしいですか？
                </div>
                <div className='row btns'>
                    <div className='col-6'>
                        <Button variant={2} onClick={onRequestClose} className="w-100">{translations.textCancel}</Button>
                    </div>
                    <div className='col-6'>
                        <Button variant={3} loading={loading} onClick={() => disconnect()} className="w-100">{'実行'}</Button>
                    </div>
                </div>
            </div >
        </Modal>
    )
}