import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { ReactComponent as DefaultItemImg } from "../assets/images/item_img.svg";
import { addGradientColorByClass } from "../components/commonFnc";

const Guide = () => {
  const steps = [
    {
      title: "STEP 1.  新規会員登録を行う",
      description: "「新規会員登録」ボタンをクリックして、登録を開始します。",
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_1.webp"),
        require("../assets/images/mobile_g/1.png"),
      ],
    },
    {
      title: "STEP 2.  Octillionにアカウント開設を行う",
      description: `Octillionのサービスを利用するために、まず会員登録を行う必要があります。これはメールアドレスと携帯電話番号の登録を意味します。
            まずは、「Octillionアカウント登録」のボタンをクリックします。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_2.webp"),
        require("../assets/images/mobile_g/2.png"),
      ],
    },
    {
      title: "STEP 3. メールアドレスの登録",
      description: `最初に登録したいメールアドレスを入力し、「メールを送信する」ボタンをクリックします。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_3.webp"),
        require("../assets/images/mobile_g/3.png"),
      ],
    },
    {
      title: "STEP 4.  認証番号の入力",
      description: `登録済みのメールアドレスに6桁の認証番号がメールにて送信され、その番号を入力し「確定」ボタンをクリックします。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_4.webp"),
        require("../assets/images/mobile_g/4.png"),
      ],
    },
    {
      title: "STEP 5.  携帯電話番号の登録を行う",
      description: `次に携帯電話番号のを入力し、「SMSを送信する」ボタンをクリックします。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_5.webp"),
        require("../assets/images/mobile_g/5.png"),
      ],
    },
    {
      title: "STEP ６.  認証番号（ワンタイムパスワード）の入力",
      description: `登録済みの携帯電話番号に6桁の認証番号がSMSにて送信され、その番号を入力し「確定」ボタンをクリックします。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_6.webp"),
        require("../assets/images/mobile_g/6.png"),
      ],
    },
    {
      title: "STEP 7.  パスワードの設定",
      description: `アカウント名（ニックネーム）とログインに利用されるパスワードを入力し、「アカウント登録する」ボタンをクリックします。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_7.webp"),
        require("../assets/images/mobile_g/7.png"),
      ],
    },
    {
      title: "STEP 8.  ANICANA Walletの登録",
      description: `登録が正常に終わったら、「登録完了」ステータスが表示されます。次にANICANA Walletを登録を行うために、ANICANAのアイコンをクリックします。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_8.webp"),
        require("../assets/images/mobile_g/8.png"),
      ],
    },
    // {
    //     title: 'STEP 9.  web3authのアカウントの作成',
    //     description: `ANICANA Walletの登録にweb3authのサービスを使います。web3authのアカウントをお持ちでない方がアカウントを作成していただき、既にお持ちの方は既存アカウントで連携を行なってください。`,
    //     image: require('../assets/images/guide/how_to_register/how_to_register_guide_9_bak.webp')
    // },
    {
      title: "STEP 9.  LEVICA IDの作成",
      description: `ANICANA Walletの登録には、web3authのサービスを利用します。web3authのアカウントはLEVIAS IDと連携するため、LEVIAS IDをお持ちでない方は新たに作成していただき、既にお持ちの方は既存のLEVIAS IDで連携を行なってください。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_9.webp"),
        require("../assets/images/mobile_g/99.png"),
      ],
    },
    {
      title: "STEP 10.  登録完了",
      description: `登録完了後は、口座登録ボタンを押すことで、銀行口座を登録することが出来ます。スキップすると、TOP画面に戻ります。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_10.webp"),
        require("../assets/images/mobile_g/111.png"),
      ],
    },
    {
      title: "STEP 11.  受取銀行口座の登録",
      description: `売り希望する場合、取引成立後、対価を受け取るための口座を登録する必要があります。アカウントとウォレット登録後、受取口座の登録も事前に行うと、以降の売り希望の実施がスムーズに行われます。`,
      image: [
        require("../assets/images/guide/how_to_register/how_to_register_guide_11.webp"),
        require("../assets/images/mobile_g/122.png"),
      ],
    },
  ];

  useEffect(() => {
    addGradientColorByClass("navGuide");
  }, []);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <>
      <div className="px-3 px-lg-5 my-5" id="detail">
        <div className="flex-center">
          <h2 className="text-center title mb-5 pt-5 ">
            OCTILLIONアカウント登録やANICANA Walletの設定方法
          </h2>
        </div>
        {steps.map((step, index) => (
          <div key={`how_to_guide_step_${index}`} style={{ marginBottom: 130 }}>
            <h2 className="fw-500 text-start my-5" style={{ fontSize: 20 }}>
              {step.title}
            </h2>
            <div className="divider mb-5"></div>
            <p className="text-justify mb-5" style={{ fontSize: 20 }}>
              {step.description}
            </p>
            <div className="flex-center">
              <img
                src={step.image[windowSize >= 1000 ? 0 : 1]}
                className="w-100 guide-image"
              />
            </div>
          </div>
        ))}
        <Footer />
      </div>
    </>
  );
};

export default Guide;
