import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TokenType } from "../../components/enum";
import { SortSelect } from "../../components/Form";
import Item, { SearchItem, TotalBid } from "../../components/Item";
import Loading from "../../components/Loading";
import MyPageWrapper from "../../components/MyPageWrapper";
import Swal from "../../components/Swal";
import { getData, postData } from "../../services/fetch";

const MyPersona = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState("DESC");
  const [loading, setLoading] = useState(false);

  const getItems = async () => {
    setTotal(0);
    setLoading(true);

    //get last seen time
    var last_seen_res = await getData(
      `api/get-last-seen-token-type?token_type_id=${TokenType.Persona}`
    );
    var last_seen_time = null;
    if (last_seen_res.ok) {
      last_seen_time = last_seen_res.data.last_seen_at
        ? new Date(last_seen_res.data.last_seen_at)
        : null;
      // console.log('last seen time => ' + last_seen_time);
    }

    //get tokens
    var response = await getData(
      `api/tokens/my-tokens?token_type_id=${TokenType.Persona}`
    );
    if (response.ok) {
      if (response.data.results) {
        var tokens = response.data.results.tokens;

        //add new tag
        if (last_seen_time) {
          tokens.map((token) => {
            // console.log(token.last_updated);
            var last_updated = new Date(token.last_updated.replace("T", " "));
            if (last_seen_time < last_updated) {
              console.log("new");
              token["new"] = 1;
            }
          });
        }

        // setItems(response.data.results.tokens);
        setItems(tokens);
        setTotal(response.data.results.total);
      } else {
        Swal.fire({
          icon: "error",
          title: "エラーが発生しました",
          text: response.data.message,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "エラーが発生しました",
        text: response.data.message,
      });
    }

    //update last seen time
    await postData(`api/update-last-seen-token-type`, {
      token_type_id: TokenType.Persona,
    });
    setLoading(false);
  };

  useEffect(() => {
    getItems();

    return () => {
      setItems([]);
      setTotal(0);
      setLoading(false);
    };
  }, []);

  return (
    <MyPageWrapper
      pageid="保有ペルソナ"
      sortBy={sortBy}
      onChangeSort={(e) => setSortBy(e.target.value)}
    >
      <div className="d-flex flex-row align-items-end justify-content-between mb-4">
        <div className="d-flex flex-row align-items-end justify-content-start">
          <h3 className="text-start mb-0 me-3">保有ペルソナ</h3>
          <span>{total}件</span>
        </div>
        <SortSelect
          className="d-none d-lg-block"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        />
      </div>

      <div className="my-5">
        <TotalBid />
      </div>
      <div className="row item-list" style={{ maxWidth: 1500 }}>
        {loading ? (
          <Loading />
        ) : (
          items.map((item, index) => (
            <div
              key={index}
              className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 d-flex justify-content-center"
            >
              <Item
                item={item}
                type="arcana"
                buttonText={"PERSONA"}
                onClickBtn={() =>
                  navigate(`/detail/${item.token_type_id}/${item.token_id}`)
                }
              />
            </div>
          ))
        )}

        {/* {loading ? (
          <Loading />
        ) : (
          items.map((item, index) => (
            <div
              key={index}
              className="col-res item-col col-res-xxxxl-20 col-res-xxl-3 col-md-4 col-sm-6 col-12"
            >
              <Item
                item={item}
                buttonText={"PERSONA"}
                onClickBtn={() =>
                  navigate(`/detail/${item.token_type_id}/${item.token_id}`)
                }
              />
            </div>
          ))
        )} */}
      </div>
    </MyPageWrapper>
  );
};

export default MyPersona;
