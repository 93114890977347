import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    REACT_APP_API_URL,
    REACT_APP_ARCANA_CONTRACT_ADDRESS,
} from '../../config'
import RPC, { initWeb3 } from "../../components/web3RPC";
import Swal from "../../components/Swal";
import { Button } from "../../components/Form";
import { postData } from "../../services/fetch";
import { Web3authContext } from "../../context/web3authContext";
import {getLocalIdToken} from "../../services/storage";
import { TokenType } from "../../components/enum";

export const Arcanagen = () => {
    const { web3SoftLogout,web3Login,loginLevica,login,provider,web3auth,setId_token } = useContext(Web3authContext);
    const [error, setError] = useState(null);
    const [result, setResult] = useState('');
    const [transaction_id, setTransaction_id] = useState('');
    const [tokenTypeId, setTokenTypeId] = useState(null);

    const [image, setImage] = useState([]);

    const tokenName = React.useRef(null);
    const creatorName = React.useRef(null);
    const tokenIdRef = React.useRef(null);
    const tokenIdRef2 = React.useRef(null);
    const tokenIdRef3 = React.useRef(null);
    const tokenAddressRef = React.useRef(null);
    const fromWalletAddressRef = React.useRef(null); //0xFf5BC900110f5c4eb6Ce2faf2081B4151655B3f3
    const toWalletAddressRef = React.useRef(null); //0x724BB427E1851473081b85F3eCc9dD8720BA99c8
    const { seed, egg_id, sig } = useParams();

    const [id_token, setIdToken] = useState(null);


    const location = useLocation();
    const search = location.search;
    const query = new URLSearchParams(search);


    useEffect(() => {
        console.log("INIT")
        let id_token = query.get('id_token')
        if(id_token){
            setId_token(id_token)
        }
    },[]);

    useEffect(() => {
        if(provider){
            console.log('PROVIDER SET')
        }else{
            console.log('PROVIDER NOT SET')
            let id_token = query.get('id_token')
            if(id_token){
                console.log("TOKEN EXIST")
                setId_token(id_token)
                login(id_token)
            }else{
                login()
            }
        }
    },[provider]);



    const init = async () => {
        try {

        } catch (e) {
            console.error(e)
        }
    };

    const approveContract = async (token_type_id, token_id) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        var hash = await rpc.approveContract(token_type_id, token_id);
        if (hash) {
            setTransaction_id(hash);
        }
    };

    const approveToken = () => {
        approveContract(1, tokenIdRef.current.value);
    }

    const transferContract = async (token_type_id, to, token_id) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        var hash = await rpc.transferContract(token_type_id, to, token_id);
        if (hash) {
            setTransaction_id(hash);
        }
    };

    const transferToken = () => {
        if (toWalletAddressRef.current.value && tokenIdRef.current.value) {
            transferContract(TokenType.Arcana, toWalletAddressRef.current.value, tokenIdRef.current.value);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: 'Please enter To Wallet Address and Token ID'
            })
        }
    }

    const getBlochchainParams = async () => {
        var sig = await getTransferSignature();
        var owner = await getAccounts();
        console.log(tokenIdRef.current.value, toWalletAddressRef.current.value, sig, owner)
        setResult(JSON.stringify({
            "category": REACT_APP_ARCANA_CONTRACT_ADDRESS,
            "token_id": tokenIdRef.current.value,
            "sender_sign": sig,
            "to_wallet_address": toWalletAddressRef.current.value,
            "owner": owner
        }, null, 4));
    }

    useEffect(() => {
        if (transaction_id) {
            setResult(`transaction_id: ${transaction_id}`);
            Swal.fire({
                icon: 'success',
                title: 'The transaction is now pending.',
                text: `Transaction ID is ${transaction_id}`
            })
        }
    }, [transaction_id])

    const handleSubmit = event => {
        event.preventDefault();
        console.log(tokenName.current.value);
    }
    const handleFile = event => {
        const formData = new FormData();
        formData.append("file", image);
        console.log(tokenName)
        formData.append('creatorName', creatorName.current.value)
        formData.append('tokenName', tokenName.current.value)

        fetch(REACT_APP_API_URL + "arcana-metadata", {
            method: 'POST',

            body: formData,
            dataType: "jsonp"
        })
    };
    const onChangeImg = event => {
        console.log(event.target.files[0])
        setImage(event.target.files[0])
    };


    useEffect(() => {
        if (!web3auth) {
            init();
        }
        toWalletAddressRef.current.value = '0xa919aeC534F8285DeD9Cf821bc37A1146C867d85';

        return () => {
            setTransaction_id('');
        }
    }, []);

    // const login = async () => {
    //     if(!provider){
    //         loginLevica();
    //         return;
    //     }
    // };

    const checkWeb3 = () => {
        if(!provider){
            setResult('Connectされていません')
            return false;
        }

        return true;
    }

    const getUserInfo = async () => {
        if (!checkWeb3()) {
            console.log("web3auth not initialized yet");
            return;
        }
        const user = await web3auth.getUserInfo();
        setResult(JSON.stringify(user, undefined, 4));
    };

    const logout = async () => {
        if (!checkWeb3()) {
            console.log("web3auth not initialized yet");
            return;
        }
        await web3auth.logout();
    };

    const getChainId = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const chainId = await rpc.getChainId();
        setResult(chainId);
    };

    const getAccounts = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const address = await rpc.getAccounts();
        setResult(address);
        return address;
    };

    const getBalance = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const balance = await rpc.getBalance();
        setResult(balance);
    };

    const sendTransaction = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const receipt = await rpc.sendTransaction();
        setResult(JSON.stringify(receipt, undefined, 4));
    };

    const signMessage = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const signedMessage = await rpc.signMessage();
        setResult(signedMessage);
    };

    const getPrivateKey = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const privateKey = await rpc.getPrivateKey();
        setResult(JSON.stringify(privateKey, null, 4));
    };

    const getMinter = async () => {
        if (!checkWeb3()) {
            setResult("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        rpc.isMinter(tokenTypeId,'0xA5CdBC310875699f313F65BcA23067CD5F972e71').then( function (hash) {
            console.log(hash)
            setResult(hash)
        }).catch(error => {
            setResult('')
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: error.message,
            });
        });
    };

    const getOwner = async () => {
        if (!checkWeb3()) {
            setResult("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        rpc.ownerOf(tokenTypeId,tokenIdRef2.current.value).then( function (hash) {
            console.log(hash)
            setResult(hash)
        }).catch(error => {
            setResult('')
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: error.message,
            });
        });
    };

    const getApproved = async () => {
        if (!checkWeb3()) {
            setResult("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        rpc.getApproved(1,tokenIdRef3.current.value).then( function (hash) {
            console.log(hash)
            setResult(hash)
        }).catch(error => {
            setResult('')
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: error.message,
            });
        });
    };


    const getTokenCount = async () => {
        if (!checkWeb3()) {
            setResult("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        rpc.balanceOf(tokenTypeId,tokenAddressRef.current.value).then( function (hash) {
            console.log(hash)
            setResult(hash)
        }).catch(error => {
            setResult('')
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: error.message,
            });
        });
    };

    const getTransferSignature = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const signature = await rpc.getTransferSignature(tokenIdRef.current.value, toWalletAddressRef.current.value);
        setResult(signature);
        return signature;
    };

    const generateArcana = async () => {
        if (!checkWeb3()) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const address = await rpc.getAccounts()
        const privateKey = await rpc.generateArkana(egg_id, address, seed, sig);
        setResult(privateKey);
    }

    const loggedInView = (
        <div className="d-flex flex-row flex-wrap align-items-center justify-content-start">
            {/*<Button className={'btn btn-custom-2 me-3 mb-3'} onClick={generateArcana} >*/}
            {/*    generateArcana*/}
            {/*</Button>*/}
            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={getUserInfo} >
                Get User Info
            </Button>
            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={getChainId} >
                Get Chain ID
            </Button>
            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={getAccounts} >
                Get Accounts
            </Button>
            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={getBalance} >
                Get Balance
            </Button>
            {/*<Button className={'btn btn-custom-2 me-3 mb-3'} onClick={sendTransaction} >*/}
            {/*    Send Transaction*/}
            {/*</Button>*/}
            {/*<Button className={'btn btn-custom-2 me-3 mb-3'} onClick={signMessage} >*/}
            {/*    Sign Message*/}
            {/*</Button>*/}
            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={getPrivateKey} >
                Get Private Key
            </Button>
            {/*<Button className={'btn btn-custom-2 me-3 mb-3'} onClick={getTransferSignature} >*/}
            {/*    Get Transfer Signature*/}
            {/*</Button>*/}
        </div>
    );



    return (
        <div>
            <div className="container arcana-generator">
                    <div className="gen-form">
                        <div className="container my-3">
                            {provider?'connected':
                            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={loginLevica}>
                                Connect
                            </Button>
                            }
                        </div>
                    </div>
                    <div className="gen-form" >
                        <div className="container my-3">
                            <div className="row">

                                <div className="col-12">
                                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-start">
                                        <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={logout}>
                                            Log Out
                                        </Button>
                                        <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={web3SoftLogout}>
                                            Log Out (Global)
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <h3 className="mb-3">Token Transfer</h3>
                                    <h6>Data To Test</h6>
                                    <code className="pointer"
                                          onClick={() => toWalletAddressRef.current.value = '0xFf5BC900110f5c4eb6Ce2faf2081B4151655B3f3'}>
                                        0xFf5BC900110f5c4eb6Ce2faf2081B4151655B3f3
                                    </code>
                                    <br/>
                                    <code className="pointer"
                                          onClick={() => toWalletAddressRef.current.value = '0xa919aeC534F8285DeD9Cf821bc37A1146C867d85'}>
                                        0xa919aeC534F8285DeD9Cf821bc37A1146C867d85
                                    </code>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            {/* <div className="mb-3">
                                    <label className="form-label">From Wallet Address</label>
                                    <input type="text" ref={fromWalletAddressRef} className="form-control" />
                                </div> */}
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label className="form-label">To Wallet Address</label>
                                                    <input type="text" ref={toWalletAddressRef}
                                                           className="form-control"/>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label className="form-label">Token Id</label>
                                                    <input type="text" ref={tokenIdRef} className="form-control"/>
                                                </div>
                                            </div>
                                            <button type="button" onClick={approveToken}
                                                    className="btn btn-custom-2 me-3 mb-3">Approve With Smart Contract
                                            </button>
                                            <button type="button" onClick={transferToken}
                                                    className="btn btn-custom-2 me-3 mb-3">Transfer (method 1) With
                                                Smart Contract
                                            </button>
                                            {/* <button type="button" onClick={transferTokenWithBlochchain} className="btn btn-custom-2 me-3">Transfer With Blockchain API</button> */}
                                            <button type="button" onClick={getBlochchainParams}
                                                    className="btn btn-custom-2 me-3">Get Blockchain Params
                                            </button>
                                        </div>
                                    </div>
                                    <div className="divider my-3"></div>
                                    <div style={{marginTop: 20 + "px"}}>
                                        {loggedInView}
                                    </div>
                                    <hr />
                                    <div className="col-12">
                                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-start">
                                            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={() => setTokenTypeId(1)}>
                                                ARCANA
                                            </Button>
                                            <Button className={'btn btn-custom-2 me-3 mb-3'} onClick={() => setTokenTypeId(100)}>
                                                EGG
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={"row mt-3"}>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <label className="form-label">Token ID</label>
                                                <input type="text" ref={tokenIdRef2} placeholder={'token id'} className="form-control"/>
                                            </div>

                                        </div>

                                        <button type="button" onClick={getOwner}
                                                className="btn btn-custom-2 me-3 mb-3">getOwner
                                        </button>
                                    </div>

                                    <div className={"row mt-3"}>

                                        <button type="button" onClick={getMinter}
                                                className="btn btn-custom-2 me-3 mb-3">getMinter
                                        </button>
                                    </div>


                                    <div className={"row mt-3"}>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <label className="form-label">Token Count</label>
                                                <input type="text" ref={tokenAddressRef} placeholder={'wallet adddress'} className="form-control"/>
                                            </div>

                                        </div>

                                        <button type="button" onClick={getTokenCount}
                                                className="btn btn-custom-2 me-3 mb-3">getTokenCount
                                        </button>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <label className="form-label">get Approved</label>
                                                <input type="text" ref={tokenIdRef3} placeholder={'token id'} className="form-control"/>
                                            </div>

                                        </div>

                                        <button type="button" onClick={getApproved}
                                                className="btn btn-custom-2 me-3 mb-3">getApproved
                                        </button>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div id="console" className="" style={{whiteSpace: "pre-line"}}>
                                        <h6>Result</h6>
                                        <div className="card p-3"
                                             style={{borderRadius: 5, minHeight: 500, maxHeight: '100vh'}}>
                                            <code
                                                style={{whiteSpace: "pre-line", wordBreak: 'break-all'}}>{result}</code>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}
