import React from "react";
import { isMobile } from "react-device-detect";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { color } from "../assets/style";
import { Button } from "./Form";
import { CloseIcon } from "./Icon";

const Modal = (props) => {
  return (
    <ReactModal
      {...props}
      // className={"Modal"}
      shouldCloseOnOverlayClick={false}
      // onRequestClose={(e) => { e.stopPropagation(); if (props.onRequestClose) props.onRequestClose() }}
      style={{
        overlay: {
          zIndex: 1021,
          overflow: "auto",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          border: `3px solid ${color.primary}`,
          background: `linear-gradient(180deg, #4B597F 0%, #1F2639 100%), linear-gradient(0deg, #495376, #495376)`,
          borderRadius: 15,
          color: "#fff",
          padding: window.innerWidth < 1728 ? 20 : 45,
          width: window.innerWidth <= 768 ? "calc(100% - 70px)" : "100%",
          maxWidth: props.lg
            ? window.innerWidth < 1728
              ? 660
              : 750
            : window.innerWidth < 1728
            ? 600
            : 680,
        },
      }}
    >
      <div className="modal-content text-center">
        {!props.noClose && (
          <CloseIcon
            onClick={props.onRequestClose}
            className="position-absolute pointer"
            style={{ width: 26, height: 26, right: 0, stroke: "#FFF" }}
          />
        )}
        {props.children}
      </div>
    </ReactModal>
  );
};

export default Modal;

export const LoginNeededModal = (props) => {
  const navigate = useNavigate();
  return (
    <Modal {...props}>
      <div
        id="detail"
        className="container-fluid selling-price-confirmation text-center mx-0"
      >
        <h3 className="fw-bold mb-5" style={{ lineHeight: "32px" }}>
          ログインしてください
        </h3>
        <p className="mb-4">取引を実行するためにログインが必要となります。</p>
        <p>
          アカウントを持っていない方は
          <br />
          お手数ですが、アカウントの作成をお願いします。
        </p>
        <div className="px-5">
          <div className="row mt-5 mb-4">
            <div className="col-12 flex-center">
              <Button
                variant={2}
                onClick={() => navigate("/login")}
                className="btn-mini"
              >
                Login/Signup
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
