import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export function useDidUpdateEffect(fn, inputs) {
    const fncRef = useRef();
    fncRef.current = fn;
    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            return fncRef.current();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
}

export function useOutsideAlerter(ref, fnc) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                fnc();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useScrollToTop = (inputs) => {
    useEffect(() => {
        document.getElementById('subbody').scrollTo(0, 0);
    }, [inputs])
}

export const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
}