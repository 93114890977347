import { REACT_APP_ANIMA_CONTRACT_ADDRESS, REACT_APP_ARCANA_CONTRACT_ADDRESS, REACT_APP_PERSONA_CONTRACT_ADDRESS } from "../config";
import arcana_abi from '../abi/arcana_abi.json';
import persona_abi from '../abi/persona_abi.json';
import anima_abi from '../abi/anima_abi.json';

export const TokenType = Object.freeze({
  Arcana: 1,
  Anima: 2,
  Persona: 3,
  getABI: (id) => getABI(id),
  getContractAddress: (id) => getContractAddress(id)
})


export const SortType = Object.freeze({
  Desc: "DESC",
  Asc: "ASC",
});

export const OrderType = Object.freeze({
  Buy: "buy",
  Sell: "sell",
});

export const PaymentType = Object.freeze({
  Web: "web",
  QR: "qr",
});

export const WrapperType = {
  Search: 1,
  MyPage: 2,
};

export const getContractAddress = id => {
  switch (Number(id)) {
    case TokenType.Arcana: return REACT_APP_ARCANA_CONTRACT_ADDRESS;
    case TokenType.Persona: return REACT_APP_PERSONA_CONTRACT_ADDRESS;
    case TokenType.Anima: return REACT_APP_ANIMA_CONTRACT_ADDRESS;
    default: return undefined;
  }
}

export const getABI = id => {
  switch (Number(id)) {
    case TokenType.Arcana: return arcana_abi;
    case TokenType.Persona: return persona_abi;
    case TokenType.Anima: return anima_abi;
    default: return undefined;
  }
}

export const SearchType = {
  Checkbox: 1,
  Range: 2,
  Dropdown: 3,
  Text: 4,
  Date: 5,
  DateRange: 6,
};

export const LevicaTransactionStatus = Object.freeze({
  pending: "pending",
  payment_completed: "payment_completed",
  transaction_completed: "transaction_completed",
  transaction_fail: "transaction_fail",
  transaction_canceled: "transaction_canceled",
});

export const Elements = [
  "Eternal Tree",
  "Crimson Flame",
  "Adamantine Rock",
  "Peerless Steel ",
  "Heavenly Dew",
  "Golden Light",
  "Unfathomable Abyss",
];

export const BankAccountType = Object.freeze({
  普通: "0100",
  当坐: "0200",
});

export const paymentCurrency = Object.freeze({
  Anima: 2,
  Yen: 1,
})

export const currencyUnit = currency => {
  switch (Number(currency)) {
    case paymentCurrency.Yen: return '¥';
    case paymentCurrency.Anima: return 'ANM';
    default: return '';
  }
}

export const TradeStatus = {
  MATCHED: 'matched',
  TOKEN_RECEIVING: 'token_receiving',
  TOKEN_RECEIVED: 'token_received',
  MONEY_RECEIVED: 'money_received',
  TOKEN_SENDING: 'token_sending',
  TOKEN_SENT: 'token_sent',
  TRADED: 'traded',
  CANCELLED: 'cancelled',
  ERROR: 'error',
};