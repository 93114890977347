import React from 'react'
import RSwitch from "react-switch";
import { color } from '../assets/style';

const Switch = (props) => {
  return (
    <RSwitch {...props} checkedIcon={false} uncheckedIcon={false} onColor={color.primary} offColor={'#D9D9D9'} />
  )
}

export default Switch