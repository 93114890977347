import React, { useState } from "react";
import { SortType } from "../components/enum";
import { Button } from "../components/Form";
import { CloseIcon } from "../components/Icon";

const SortModalview = ({ setShowMobileSort, sortBy, doSort }) => {
  const [sortValue, setSortValue] = useState(sortBy);
  return (
    <div className="sort_modal">
      <div className="modal_header">
        <div className="header_left">SORT BY</div>
        <div>
          <CloseIcon
            className="btn_close_white"
            onClick={() => setShowMobileSort(false)}
          />
        </div>
      </div>

      <div style={{ marginTop: 64 }}>
        <div className="modal_body">
          <div
            className={
              sortValue == SortType.Desc ? "selected_color" : "label_color"
            }
            id={SortType.Desc}
            onClick={(e) => setSortValue(e.target.id)}
          >
            Recently created
          </div>
          <div>
            <input
              type="radio"
              name="sort"
              style={{ width: 25, height: 25 }}
              checked={sortValue == SortType.Desc ? true : false}
              value={SortType.Desc}
              onChange={(e) => setSortValue(e.target.value)}
            />
          </div>
        </div>
        <div className="modal_body mt-4">
          <div
            className={
              sortValue == SortType.Asc ? "selected_color" : "label_color"
            }
            id={SortType.Asc}
            onClick={(e) => setSortValue(e.target.id)}
          >
            Recently received
          </div>
          <div>
            <input
              type="radio"
              name="sort"
              style={{
                width: 25,
                height: 25,
              }}
              checked={sortValue == SortType.Asc ? true : false}
              value={SortType.Asc}
              onChange={(e) => setSortValue(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          width: "90%",
          left: 17,
          bottom: 20,
        }}
      >
        <Button
          variant={4}
          className="pointer"
          style={{ width: "100%" }}
          onClick={() => {
            doSort(sortValue);
            setShowMobileSort(false);
          }}
        >
          <p className="mb-0">DONE</p>
        </Button>
      </div>
    </div>
  );
};

export default SortModalview;
