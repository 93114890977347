import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatMoney, getTokenDetail } from '../../components/commonFnc'
import { Button, StatusText } from '../../components/Form'
import { ChevronRight2Icon } from '../../components/Icon'
import Loading from '../../components/Loading'
import MyPageWrapper from '../../components/MyPageWrapper'
import Pagination from '../../components/Pagination'
import RNDataTable from '../../components/RNDataTable'
import SubNav from '../../components/SubNav'
import { getData } from '../../services/fetch'
import { translations } from "../../services/localization";

const SellTransaction = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSellTransactions();

    return () => {
      setData([]);
    }
  }, [page])

  const getSellTransactions = async () => {
    setLoading(true);
    var response = await getData(`api/trades/get-sell-transactions?page=${page}`, true);
    if (response.ok) {
      var resp = response.data;
      setData(resp.sell_transactions);
      setPage(Number(resp.current_page));
      setLast_page(resp.last_page);
    }
    setLoading(false);
  }

  const goToDetail = id => {
    navigate(`/mypage/trade/detail/${id}`, { state: { type: 'sell' } })
  }

  const columns = [
    {
      name: 'トークン',
      selector: row => row.token_id,
      cell: row =>
        <div className='d-flex align-items-center token-cell'>
          <Link to={`/detail/${row.token_type_id}/${row.token_id}`}>
            <img src={row.token ? row.token.image : ''} />
          </Link>
          <div className='d-flex flex-column'>
            <Link className='mb-2 name text-reset' to={`/detail/${row.token_type_id}/${row.token_id}`}>{row.token ? row.token.name : ''}</Link>
          </div>
        </div>,
      center: true,
      width: '300px',
      style: {
        justifyContent: 'flex-start',
      },
    },
    {
      name: '取引価格',
      selector: row => formatMoney(row.amount, row.currency),
      right: true,
    },
    {
      name: '落札日',
      selector: row => moment(row.created_at).format("yyyy/MM/DD"),
      right: true,
    },
    {
      name: 'ステータス',
      selector: row => <StatusText status={row.status} text={row.status_jp} onClick={() => goToDetail(row.id)} />,
    },
    {
      name: '支払い日',
      selector: row => row.traded_at ? moment(row.traded_at).format("yyyy/MM/DD") : '',
      right: true
    },
    {
      name: '取引明細/領収書',
      selector: row => <ChevronRight2Icon onClick={() => goToDetail(row.id)} />,
      center: true
    }
  ];
  return (
    <MyPageWrapper pageid='売り取引'>
      <SubNav pageid='売り取引' items={[
        {
          id: '買い取引',
          name: translations.myPageWrapper.list.buyTransactions,
          to: '/mypage/buy-transactions',
        },
        {
          id: '売り取引',
          name: translations.myPageWrapper.list.sellTransactions,
          to: '/mypage/sell-transactions',
        },
      ]} />
      <div className='d-flex flex-row align-items-end mb-3 d-none d-sm-block'>
        <h3 className='text-start'>{translations.myPageWrapper.list.sellTransactions}</h3>
      </div>
      {
        loading ? <Loading /> :
          <>
            <RNDataTable
              columns={columns}
              data={data}
              onRowClicked={(row) => goToDetail(row.id)}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer'
                  },
                  stripedStyle: {
                    '&:nth-of-type(odd)': {
                      backgroundColor: '#EDF1FB',
                    },
                  },
                },
              }}
              noDataComponent={<span style={{ marginTop: 50 }}>{translations.emptylist_messages.noTradingHistory}</span>}
            />
            <Pagination onPageChange={page => setPage(page)} page={page} last_page={last_page} />
          </>
      }
    </MyPageWrapper>
  )
}

export default SellTransaction
