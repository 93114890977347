import React from "react";
import { ReactComponent as ArrowRight } from "../assets/icons/arrow_right.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import { ReactComponent as Bookmark } from "../assets/icons/bookmark.svg";
import { ReactComponent as PlusCircle } from "../assets/icons/plus_circle.svg";
import { ReactComponent as Wallet } from "../assets/icons/wallet.svg";
import { ReactComponent as Collection } from "../assets/icons/collection.svg";
import { ReactComponent as NFTList } from "../assets/icons/nft_list.svg";
import { ReactComponent as OfferHammer } from "../assets/icons/offer_hammer.svg";
import { ReactComponent as User } from "../assets/icons/user_gradient.svg";
import { ReactComponent as Bell } from "../assets/icons/bell.svg";
import { ReactComponent as ChevronRight } from "../assets/icons/chevron_right.svg";
import { ReactComponent as ChevronRight2 } from "../assets/icons/chevron_right_2.svg";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron_down.svg";
import { ReactComponent as Category } from "../assets/icons/category.svg";
import { ReactComponent as Element } from "../assets/icons/elements.svg";
import { ReactComponent as Star } from "../assets/icons/star.svg";
import { ReactComponent as PersonaHeader } from "../assets/icons/personastarHeader.svg";
import { ReactComponent as DNA } from "../assets/icons/dna.svg";
import { ReactComponent as Birthday } from "../assets/icons/birthday.svg";
import { ReactComponent as Anima } from "../assets/icons/anima.svg";
import { ReactComponent as Vitality } from "../assets/icons/vitality.svg";
import { ReactComponent as Magic } from "../assets/icons/magic.svg";
import { ReactComponent as Force } from "../assets/icons/force.svg";
import { ReactComponent as Abyss } from "../assets/icons/abyss.svg";
import { ReactComponent as Determination } from "../assets/icons/determination.svg";
import { ReactComponent as Mind } from "../assets/icons/mind.svg";
import { ReactComponent as Intelligence } from "../assets/icons/intelligence.svg";
import { ReactComponent as Heart } from "../assets/icons/heart.svg";
import { ReactComponent as Anicana_mb } from "../assets/icons/arcana_mb.svg";
import { ReactComponent as Anicana } from "../assets/icons/anicana.svg";
import { ReactComponent as Arcana } from "../assets/icons/arcana.svg";
import { ReactComponent as ArcanaWhite } from "../assets/icons/arcana_white.svg";
import { ReactComponent as Persona } from "../assets/icons/persona_icon.svg";
import { ReactComponent as Lock } from "../assets/icons/lock.svg";
import { ReactComponent as Back } from "../assets/icons/back.svg";
import { ReactComponent as GoBack } from "../assets/icons/goback.svg";
import { ReactComponent as Word_A } from "../assets/icons/word_a.svg";
import { ReactComponent as Word_P } from "../assets/icons/word_p.svg";
import { ReactComponent as Buy } from "../assets/icons/buy.svg";
import { ReactComponent as Sell } from "../assets/icons/sell.svg";
import { ReactComponent as Bid } from "../assets/icons/bid.svg";
import { ReactComponent as Ask } from "../assets/icons/ask.svg";
import { ReactComponent as History } from "../assets/icons/history.svg";
import { ReactComponent as Function } from "../assets/icons/function.svg";
import { ReactComponent as Payment } from "../assets/icons/payment.svg";
import { ReactComponent as Setting } from "../assets/icons/setting.svg";
import { ReactComponent as Logout } from "../assets/icons/logout.svg";
import { ReactComponent as Menu } from "../assets/icons/menu.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as Sort } from "../assets/icons/sort.svg";
import { ReactComponent as Filter } from "../assets/icons/filter.svg";
import { ReactComponent as Edit } from "../assets/icons/edit.svg";
import { ReactComponent as Camera } from "../assets/icons/camera.svg";
import { ReactComponent as QuestionMark } from "../assets/icons/question_mark.svg";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";
import { ReactComponent as CopyWhite } from "../assets/icons/copy_white.svg";
import { ReactComponent as SortNFT } from "../assets/icons/sort_primary.svg";

//elements
import { ReactComponent as AdamantineRock } from "../assets/icons/elements/adamantine_rock.svg";
import { ReactComponent as CrimsonFlame } from "../assets/icons/elements/crimson_flame.svg";
import { ReactComponent as EternalTree } from "../assets/icons/elements/eternal_tree.svg";
import { ReactComponent as GoldenLight } from "../assets/icons/elements/golden_light.svg";
import { ReactComponent as HeavenlyDew } from "../assets/icons/elements/heavenly_dew.svg";
import { ReactComponent as PeerlessSteel } from "../assets/icons/elements/peerless_steel.svg";
import { ReactComponent as UnfathomableAbyss } from "../assets/icons/elements/unfathomable_abyss.svg";

//filter elements
import { ReactComponent as FilterAdamantineRock } from "../assets/icons/filter_elements/adamantine_rock.svg";
import { ReactComponent as FilterCrimsonFlame } from "../assets/icons/filter_elements/crimson_flame.svg";
import { ReactComponent as FilterEternalTree } from "../assets/icons/filter_elements/eternal_tree.svg";
import { ReactComponent as FilterGoldenLight } from "../assets/icons/filter_elements/golden_light.svg";
import { ReactComponent as FilterHeavenlyDew } from "../assets/icons/filter_elements/heavenly_dew.svg";
import { ReactComponent as FilterPeerlessSteel } from "../assets/icons/filter_elements/peerless_steel.svg";
import { ReactComponent as FilterUnfathomableAbyss } from "../assets/icons/filter_elements/unfathomable_abyss.svg";
import { ReactComponent as PersonaElement } from "../assets/icons/persona-element.svg";
import { ReactComponent as ArcanaElement } from "../assets/icons/arcana-element.svg";
//maintenance
import { ReactComponent as MaintenancePC } from "../assets/icons/maintenance_PC.svg";
import { ReactComponent as MaintenanceSP } from "../assets/icons/maintenance_SP.svg";

import { color } from "../assets/style";
import { addGradientColor, removeGradientColor } from "./commonFnc";

export const ArrowRightIcon = (props) => (
  <ArrowRight
    {...props}
    className={`icon ${props.className || ""}`}
    fillOpacity={0.5}
  />
);
export const SearchIcon = (props) => (
  <Search
    {...props}
    className={`icon ${props.className || ""}`}
    fillOpacity={0.5}
  />
);
export const BookmarkIcon = ({ className, is_bookmarked, style, ...rest }) => (
  <Bookmark
    {...rest}
    className={`icon ${className || ""}`}
    style={{ fill: is_bookmarked ? color.bookmark : color.disabled, ...style }}
  />
);
export const PlusCircleIcon = (props) => (
  <PlusCircle {...props} className={`icon ${props.className || ""}`} />
);
export const PlusGradientCircleIcon = (props) => (
  <i {...props} className={`icon plus-icon ${props.className || ""}`} />
);
export const WalletIcon = (props) => (
  <Wallet {...props} className={`icon ${props.className || ""}`} />
);
export const WalletGradientIcon = (props) => (
  <i className={`icon wallet-icon ${props.className || ""}`}></i>
); //<Wallet {...props} className={`icon ${props.className || ''}`} />
export const CollectionIcon = (props) => (
  <Collection {...props} className={`icon ${props.className || ""}`} />
);
export const NFTListIcon = (props) => (
  <NFTList {...props} className={`icon ${props.className || ""}`} />
);
export const OfferHammerIcon = (props) => (
  <OfferHammer {...props} className={`icon ${props.className || ""}`} />
);
export const UserIcon = (props) => (
  <i
    className={`icon user-icon ${props.className || ""}`}
    onMouseDown={addGradientColor}
    onMouseUp={removeGradientColor}
  ></i>
); //<User {...props} className={` ${props.className || ''}`} />
export const BellIcon = (props) => (
  <i
    className={`icon bell-icon ${props.className || ""}`}
    onMouseDown={addGradientColor}
    onMouseUp={removeGradientColor}
  ></i>
); //<Bell {...props} className={`icon ${props.className || ''}`} />
// export const BellIcon = props => <Bell className={`icon ${props.className || ''} ${props.active ? 'active' : ''}`} />
export const ChevronRightIcon = (props) => (
  <ChevronRight {...props} className={`icon ${props.className || ""}`} />
);
export const ChevronRight2Icon = (props) => (
  <ChevronRight2 {...props} className={`icon ${props.className || ""}`} />
);
export const ChevronDownIcon = (props) => (
  <ChevronDown {...props} className={`icon ${props.className || ""}`} />
);
export const CategoryIcon = (props) => (
  <Category {...props} className={`icon ${props.className || ""}`} />
);
export const ElementIcon = (props) => (
  <Element {...props} className={`icon ${props.className || ""}`} />
);
export const StarIcon = (props) => (
  <Star
    {...props}
    className={`icon ${props.className || ""}`}
    style={props.gradient === 1 ? { fill: "url(#star_paint)" } : {}}
  />
);
export const PersonaStarHeader = (props) => (
  // <personastarHeader className={`icon ${props.className || ""}`} />;
  <PersonaHeader {...props} className={`icon ${props.className || ""}`} />
);

export const DNAIcon = (props) => (
  <DNA {...props} className={`icon ${props.className || ""}`} />
);
export const BirthdayIcon = (props) => (
  <Birthday {...props} className={`icon ${props.className || ""}`} />
);
export const AnimaIcon = (props) => (
  <Anima {...props} className={`icon ${props.className || ""}`} />
);
export const VitalityIcon = (props) => (
  <Vitality {...props} className={`icon ${props.className || ""}`} />
);
export const MagicIcon = (props) => (
  <Magic {...props} className={`icon ${props.className || ""}`} />
);
export const ForceIcon = (props) => (
  <Force {...props} className={`icon ${props.className || ""}`} />
);
export const AbyssIcon = (props) => (
  <Abyss {...props} className={`icon ${props.className || ""}`} />
);
export const DeterminationIcon = (props) => (
  <Determination {...props} className={`icon ${props.className || ""}`} />
);
export const MindIcon = (props) => (
  <Mind {...props} className={`icon ${props.className || ""}`} />
);
export const IntelligenceIcon = (props) => (
  <Intelligence {...props} className={`icon ${props.className || ""}`} />
);
export const HeartIcon = (props) => (
  <Heart {...props} className={`icon ${props.className || ""}`} />
);
export const AnicanaIcon = (props) => (
  <Anicana {...props} className={`icon ${props.className || ""}`} />
);
export const ArcanaIcon = ({ className, white, ...rest }) =>
  white ? (
    <ArcanaWhite {...rest} className={`${className || ""}`} />
  ) : (
    <Arcana {...rest} className={`icon ${className || ""}`} />
  );
export const ArcanaMobileIcon = ({ className, ...rest }) => (
  <Anicana_mb {...rest} className={`icon ${className || ""}`} />
);
export const PersonaIcon = (props) => (
  <Persona {...props} className={`icon ${props.className || ""}`} />
);
export const LockIcon = (props) => (
  <Lock {...props} className={`icon ${props.className || ""}`} />
);
export const BackIcon = (props) => (
  <Back {...props} className={`icon ${props.className || ""}`} />
);
export const GoBackIcon = (props) => (
  <GoBack
    {...props}
    className={`icon ${props.className || ""}`}
    style={{
      ...props.style,
      stroke:
        props.style && props.style.stroke ? props.style.stroke : color.primary,
    }}
  />
);

export const Word_AIcon = (props) => (
  <Word_A {...props} className={`icon ${props.className || ""}`} />
);
export const Word_PIcon = (props) => (
  <Word_P {...props} className={`icon ${props.className || ""}`} />
);
export const BuyIcon = (props) => (
  <Buy {...props} className={`icon ${props.className || ""}`} />
);
export const SellIcon = (props) => (
  <Sell {...props} className={`icon ${props.className || ""}`} />
);
export const BidIcon = (props) => (
  <Bid {...props} className={`icon ${props.className || ""}`} />
);
export const AskIcon = (props) => (
  <Ask {...props} className={`icon ${props.className || ""}`} />
);
export const HistoryIcon = (props) => (
  <History {...props} className={`icon ${props.className || ""}`} />
);
export const FunctionIcon = (props) => (
  <Function {...props} className={`icon ${props.className || ""}`} />
);
export const PaymentIcon = (props) => (
  <Payment {...props} className={`icon ${props.className || ""}`} />
);
export const SettingIcon = (props) => (
  <Setting {...props} className={`icon ${props.className || ""}`} />
);
export const LogoutIcon = (props) => (
  <Logout {...props} className={`icon ${props.className || ""}`} />
);
export const MenuIcon = (props) => (
  <Menu
    {...props}
    className={`icon ${props.className || ""}`}
    style={{ stroke: color.primary }}
  />
);
export const CloseIcon = (props) => (
  <Close
    {...props}
    className={`icon ${props.className || ""}`}
    style={{
      ...props.style,
      stroke:
        props.style && props.style.stroke ? props.style.stroke : color.primary,
    }}
  />
);
export const SortIcon = (props) => (
  <Sort {...props} className={`icon ${props.className || ""}`} />
);
export const FilterIcon = (props) => (
  <Filter {...props} className={`icon ${props.className || ""}`} />
);
export const EditIcon = (props) => (
  <Edit {...props} className={`icon ${props.className || ""}`} />
);
export const CameraIcon = (props) => (
  <Camera {...props} className={`icon ${props.className || ""}`} />
);
export const QuestionMarkIcon = (props) => (
  <QuestionMark {...props} className={`icon ${props.className || ""}`} />
);
export const CopyIcon = (props) => (
  <Copy {...props} className={`icon ${props.className || ""}`} />
);
export const CopyWhiteIcon = (props) => (
  <CopyWhite {...props} className={`icon ${props.className || ""}`} />
);

//elements
export const AdamantineRockIcon = (props) => (
  <AdamantineRock
    {...props}
    style={{ ...props.style }}
    className={`icon ${props.className || ""}`}
  />
);
export const CrimsonFlameIcon = (props) => (
  <CrimsonFlame
    {...props}
    style={{ ...props.style }}
    className={`icon ${props.className || ""}`}
  />
);
export const EternalTreeIcon = (props) => (
  <EternalTree
    {...props}
    style={{ ...props.style }}
    className={`icon ${props.className || ""}`}
  />
);
export const GoldenLightIcon = (props) => (
  <GoldenLight
    {...props}
    style={{ ...props.style }}
    className={`icon ${props.className || ""}`}
  />
);
export const HeavenlyDewIcon = (props) => (
  <HeavenlyDew
    {...props}
    style={{ ...props.style }}
    className={`icon ${props.className || ""}`}
  />
);
export const PeerlessSteeIcon = (props) => (
  <PeerlessSteel
    {...props}
    style={{ ...props.style }}
    className={`icon ${props.className || ""}`}
  />
);
export const UnfathomableAbyssIcon = (props) => (
  <UnfathomableAbyss
    {...props}
    style={{ ...props.style }}
    className={`icon ${props.className || ""}`}
  />
);

//filter elements
export const FilterAdamantineRockIcon = (props) => (
  <FilterAdamantineRock
    {...props}
    style={{ ...props.style }}
    className={`${props.className || ""}`}
  />
);
export const FilterCrimsonFlameIcon = (props) => (
  <FilterCrimsonFlame
    {...props}
    style={{ ...props.style }}
    className={`${props.className || ""}`}
  />
);
export const FilterEternalTreeIcon = (props) => (
  <FilterEternalTree
    {...props}
    style={{ ...props.style }}
    className={`${props.className || ""}`}
  />
);
export const FilterGoldenLightIcon = (props) => (
  <FilterGoldenLight
    {...props}
    style={{ ...props.style }}
    className={`${props.className || ""}`}
  />
);
export const FilterHeavenlyDewIcon = (props) => (
  <FilterHeavenlyDew
    {...props}
    style={{ ...props.style }}
    className={`${props.className || ""}`}
  />
);
export const FilterPeerlessSteelIcon = (props) => (
  <FilterPeerlessSteel
    {...props}
    style={{ ...props.style }}
    className={`${props.className || ""}`}
  />
);
export const FilterUnfathomableAbyssIcon = (props) => (
  <FilterUnfathomableAbyss
    {...props}
    style={{ ...props.style }}
    className={`${props.className || ""}`}
  />
);
export const PersonaElementIcon = (props) => (
  <PersonaElement
    className={`icon ${props.className || ""} `}
    style={props.style}
  />
);
export const ArcanaElementIcon = (props) => (
  <ArcanaElement
    className={`icon ${props.className || ""} `}
    style={props.style}
  />
);
export const MaintenancePCICON = (props) => (
  <MaintenancePC
    className={`icon ${props.className || ""} `}
    style={props.style}
  />
);
export const MaintenanceSPICON = (props) => (
  <MaintenanceSP
    className={`icon ${props.className || ""} `}
    style={props.style}
  />
);
