import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { formStyles, statusColor } from "../assets/style";
import { ArrowRightIcon, CloseIcon, SearchIcon, SortIcon } from "./Icon";
import RPhoneInput from "react-phone-input-2";
import RPhoneNumberInput from "react-phone-number-input";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import ReactSelect from "react-select";
import { formatPhoneNumberForDisplay, getUniqueId } from "./commonFnc";
import { translations } from "../services/localization";
import { ReactComponent as Back } from "../assets/icons/back.svg";
import { GoBackIcon } from "./Icon";
import { SortType } from "./enum";

export const SearchBox = (props) => {
  const { style, onSubmit, inputgroupclassname, iconClassName } = props;
  return (
    <div className={`input-group search-input-group ${inputgroupclassname}`}>
      <input
        onKeyDown={(e) => {
          if (e.key === "Enter") onSubmit(e);
        }}
        type="text"
        className="form-control search-input"
        {...props}
        aria-label="search"
        aria-describedby="basic-addon2"
      />
      <SearchIcon className="search-icon" />
      <a onClick={onSubmit}>
        <span className="input-group-text">
          <ArrowRightIcon />
        </span>
      </a>
    </div>
  );
};

export const SearchBox2 = forwardRef((props, ref) => {
  const { style, onSubmit, inputgroupclassname, iconClassName, iconStyle, ...rest } =
    props;
  return (
    <div className={`input-group search-input-group-2 ${inputgroupclassname}`}>
      <input
        ref={ref}
        onKeyDown={(e) => {
          if (e.key === "Enter") onSubmit(e);
        }}
        type="text"
        className="form-control search-input-2"
        {...rest}
        aria-label="search"
        aria-describedby="basic-addon2"
      />
      <SearchIcon
        className={`search-icon-2 ${iconClassName || ""}`}
        style={iconStyle || {}}
      />
    </div>
  );
});

export const SearchBox3 = forwardRef((props, ref) => {
  const {
    style,
    onSubmit,
    inputgroupclassname,
    iconClassName,
    onClose,
    value,
    toggleSearch,
  } = props;
  return (
    <div className={`input-group search-input-group-3 ${inputgroupclassname}`}>
      <input
        ref={ref}
        onKeyDown={(e) => {
          if (e.key === "Enter") onSubmit(e);
        }}
        type="text"
        className="form-control search-input-3"
        {...props}
        aria-label="search"
      />
      {/* <a onClick={onSubmit}>
        <span className="input-group-text">
          <ArrowRightIcon />
        </span>
      </a> */}
      <GoBackIcon className="search-icon-3" onClick={toggleSearch} />
      {/* <CloseIcon className="btn-custom-close" onClick={onClose} /> */}
    </div>
  );
});

export const Button = (props) => {
  const { variant, className, children, type, loading, onClick, ...rest } =
    props;
  return (
    <LaddaButton
      data-style={EXPAND_LEFT}
      loading={loading}
      {...rest}
      type={type || "button"}
      onClick={onClick}
      className={`btn btn-primary btn-custom${
        variant ? "-" + variant : ""
      } ${className}`}
    >
      {children}
    </LaddaButton>
  );
};

export const TextBox = forwardRef((props, ref) => {
  const { variant, className, ...rest } = props;
  return (
    <input
      ref={ref}
      {...rest}
      className={`form-control textbox${
        variant && variant > 0 ? "-" + variant : ""
      } ${className || ""}`}
    />
  );
});

export const TextArea = (props) => {
  return (
    <textarea
      rows={5}
      {...props}
      className={`form-control textbox${
        props.variant && props.variant > 0 ? "-" + props.variant : ""
      } ${props.className || ""}`}
    ></textarea>
  );
};

export const TextBoxWithLabel = (props) => {
  const { label, textarea, labelClassName, labelWidth, ...rest } = props;
  return (
    <div className="row mb-4">
      <div className={`col-sm-${labelWidth || "4"} d-flex align-items-center`}>
        <span className={labelClassName}>{label}</span>
      </div>
      <div className="col-sm">
        {textarea ? (
          <TextArea {...rest} className="textarea" />
        ) : (
          <TextBox {...rest} />
        )}
      </div>
    </div>
  );
};

export const TextBoxWithLabel2 = (props) => {
  const { label, textarea } = props;
  return (
    <div className="d-flex flex-column">
      <div className={`d-flex align-items-center`}>
        <span className="title mb-2">{label}</span>
      </div>
      <div className="">
        {textarea ? (
          <TextArea {...props} className="textarea" />
        ) : (
          <TextBox {...props} />
        )}
      </div>
    </div>
  );
};

export const Select = (props) => {
  const { className, style, options, onChange, value } = props;
  return (
    <select
      className={`form-select ${className || ""}`}
      style={style}
      onChange={onChange}
      value={value}
    >
      {options &&
        options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
    </select>
  );
};

export const SelectWithLabel = (props) => {
  const { label, labelWidth, ...rest } = props;
  return (
    <div className="row mb-4">
      <div className={`col-sm-${labelWidth || 4} d-flex align-items-center`}>
        <span>{label}</span>
      </div>
      <div className="col-sm">
        <Select {...rest} />
      </div>
    </div>
  );
};

export const SortSelect = (props) => {
  const { className, value, onChange, style, options } = props;
  return (
    <Select
      className={`sort ${className || ""}`}
      style={{ width: 200, ...style, textAlign: "center" }}
      options={
        options || [
          { value: "DESC", text: translations.descendingOrder },
          { value: "ASC", text: translations.ascendingOrder },
        ]
      }
      value={value}
      onChange={onChange}
    />
  );
};

export const FakeSortSelect = ({ onPress, value }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        border: "1px solid #495376",
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onPress}
    >
      {/* {JSON.stringify(value)} */}
      <span style={{ fontSize: 22 }}>
        {value == SortType.Desc
          ? translations.descendingOrder
          : translations.ascendingOrder}
        <span>
          <SortIcon style={{ marginLeft: 10, width: 11, marginBottom: 5 }} />
        </span>
      </span>
    </div>
  );
};

export const StatusText = (props) => {
  const { status, text } = props;

  return (
    <span
      {...props}
      style={{ whiteSpace: "normal", color: statusColor[status] }}
    >
      {text}
    </span>
  );
};

export const OldPhoneInput = (props) => {
  const { value, onChange, className, inputClass, placeholder } = props;
  return (
    <RPhoneInput
      country={"jp"}
      value={value}
      onChange={onChange}
      dropdownClass="text-start"
      containerClass={`phoneInput ${className || ""}`}
      inputClass={`textbox w-100 ${inputClass || ""}`}
      buttonClass={`regionbtn ${inputClass || ""}`}
      inputStyle={{ height: 50, paddingLeft: 60, fontSize: 16 }}
      buttonStyle={{ width: 50, backgroundColor: "#fff" }}
      placeholder={placeholder}
      countryCodeEditable={false}
    />
  );
};

export const PhoneInput = (props) => {
  const { value, onChange, className, inputClass, placeholder } = props;
  const [autoFocus, setAutoFocus] = useState(false);
  const [id] = useState(getUniqueId("phoneInputId"));
  // const phoneInputRef = useRef();

  // const formatValue = () => {
  //     if (phoneInputRef.current.value) {
  //         setTimeout(() => {
  //             let raw = formatPhoneNumberForDisplay(phoneInputRef.current.value);
  //             document.getElementById(id).value = raw;
  //         }, 250)
  //     }
  // }

  const handleOnChange = (value) => {
    console.log({ value });
    onChange(value);
  };

  // useEffect(() => {
  //     if (value) {
  //         formatValue()
  //     }
  // }, [value])

  const onFocus = () => setAutoFocus(true);
  const onBlur = () => setAutoFocus(false);

  const InputComponent = React.forwardRef((props, ref) => (
    <input
      {...props}
      autoFocus={autoFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={ref}
      className="textbox w-100"
      style={{ border: "none", height: 48, padding: "0 15px 3px" }}
      autoComplete="off"
    />
  ));

  return (
    <div
      className="phoneInput textbox w-100 d-flex align-items-center"
      style={{ borderStyle: "solid", paddingLeft: 15 }}
    >
      <RPhoneNumberInput
        onBlur={() => onBlur()}
        onFocus={() => onFocus()}
        international={false}
        countryCallingCodeEditable={false}
        defaultCountry="JP"
        placeholder={placeholder}
        className={`w-100 ${inputClass || ""}`}
        inputComponent={InputComponent}
        id={id}
        value={value}
        onChange={handleOnChange}
        // autoComplete={false}
        // onBlur={() => formatValue(value, phoneInputRef.current.value)}
        // inputComponent={MyInputComponent}
        // onCountryChange={val => setCountryCode(val)}
      />
    </div>
  );
};

export const PhoneInputWithLabel = (props) => {
  const { label, labelWidth, ...rest } = props;
  return (
    <div className="row mb-4">
      <div className={`col-sm-${labelWidth || 4} d-flex align-items-center`}>
        <span>{label}</span>
      </div>
      <div className="col-sm">
        <PhoneInput {...rest} />
      </div>
    </div>
  );
};

export const DatePicker = ({ date, onChange, ...rest }) => {
  registerLocale("ja", ja);
  return (
    <ReactDatePicker
      selected={date}
      dateFormat="yyyy-MM-dd"
      onChange={onChange}
      locale={"ja"}
      {...rest}
    />
  );
};

export const AutoCompleteSelect = forwardRef((props, reff) => {
  const {
    ref,
    defaultValue,
    onChange,
    options = [],
    optionKey = "value",
    optionLabel = "label",
    isCustomOptions,
    ...rest
  } = props;
  return (
    <ReactSelect
      key={`autocomplete_true`}
      ref={reff}
      className="basic-single autocomplete"
      classNamePrefix="select"
      classNames={{
        control: (state) => "textbox",
      }}
      defaultValue={defaultValue}
      isClearable={false}
      isSearchable={true}
      onChange={onChange}
      options={
        isCustomOptions
          ? options
          : options.map((x, i) => ({
              value: x[optionKey],
              label: x[optionLabel],
              key: i,
            }))
      }
      {...rest}
    />
  );
});

export const AutoCompleteSelectWithLabel = forwardRef((props, reff) => {
  const { label, labelWidth, ref, ...rest } = props;
  return (
    <div className="row mb-4">
      <div className={`col-sm-${labelWidth || 4} d-flex align-items-center`}>
        <span>{label}</span>
      </div>
      <div className="col-sm">
        <AutoCompleteSelect {...rest} ref={reff} />
      </div>
    </div>
  );
});
