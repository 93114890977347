export const styles = {
    userIcon: {
        height: 50,
        width: 50,
        left: 1366,
        top: 15,
        borderRadius: 0,
    }
}

export const color = {
    primary: '#495376',
    secondary: '#61AD89',
    bookmark: '#FFE600',
    disabled: '#D9D9D9'
}

export const statusColor = {
    token_received: '#E88B00',
    token_sent: '#A633FF',
    traded: '#61AD89',
    cancelled: '#DB5E5F',
    money_received: '#495376',
}

export const formStyles = {
    searchInput: {
        borderRight: 'none',
    },
    searchIcon: {
        borderLeft: 'none',
        backgroundColor: '#fff',
    }
}

export const carouselStyles = {
    indicator: {

    }
}