/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, SearchBox2, SearchBox3 } from "./Form";
import { useOutsideAlerter } from "../services/hook";
import { getToken, getWalletAddress } from "../services/storage";
import {
  ArcanaIcon,
  ArcanaMobileIcon,
  AskIcon,
  BellIcon,
  BidIcon,
  BookmarkIcon,
  BuyIcon,
  CloseIcon,
  CopyIcon,
  FunctionIcon,
  HistoryIcon,
  LogoutIcon,
  MenuIcon,
  PaymentIcon,
  PersonaIcon,
  PlusCircleIcon,
  SearchIcon,
  SellIcon,
  SettingIcon,
  UserIcon,
  Word_PIcon,
} from "./Icon";
import moment from "moment";
import { HashLink } from "react-router-hash-link";
import { dispatch, useStoreState } from "./global";
import {
  addGradientColorByClass,
  copyToClipboard,
  scrollWidthOffset,
} from "./commonFnc";
import { AuthContext } from "../context/authContext";
import { getData, postData } from "../services/fetch";
import { ReactComponent as LogoImg } from "../assets/images/logo.svg";
import { ReactComponent as DefaultUserImg } from "../assets/images/default_user.svg";
import Footer from "./Footer";
import Modal from "../components/Modal";
import { logout } from "../services/fetch";
import { translations } from "../services/localization";

const AppWrapper = ({ maintenance, ...props }) => {
  const navigate = useNavigate();
  const { children } = props;
  const [search, setSearch] = useState(props.search || "");
  const [show, setShow] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const subbodyRef = useRef();
  const location = useLocation();

  const onSearch = (e) => {
    e.preventDefault();
    dispatch({ type: "updateFilter", key: "search", value: search });
    navigate(`/search`);
  };

  const toggleMenu = () => {
    console.log("click menu");
    setShow((show) => !show);
  };

  const handlePopover = (openNoti, openProfile) => {
    setOpenNoti(openNoti);
    setOpenProfile(openProfile);
  };

  const navBarContent = translations.navBar;

  const isMaintenanceMode = maintenance !== false;

  return (
    <div className="AppWrapper">
      <nav
        className="navbar navbar-expand-lg sticky-top"
        // className="navbar navbar-expand-lg sticky-top"
        id="mainNav"
      >
        <div
          className="d-flex w-100"
          style={{ paddingLeft: 18, paddingRight: 18 }}
        >
          <Link className="text-reset" to="/">
            <LogoImg className="logo" />
          </Link>
          { !isMaintenanceMode && <>
          <AdditionalNavItems
            className="d-lg-none ms-auto"
            onSearch={onSearch}
            search={search}
            setSearch={setSearch}
            onPopover={handlePopover}
          />
          <button className="navbar-toggler" onClick={toggleMenu}>
            <MenuIcon />
          </button>
          {/* hide menu start */}
          <div
            className={`collapse navbar-collapse ${show ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            {location.pathname !== "/search" && (
              <DynamicSearchBox
                onSearch={onSearch}
                setSearch={setSearch}
                search={search}
              />
            )}
            <ul className="navbar-nav ms-auto  mb-2 mb-lg-0">
              <li className="nav-item d-lg-none close">
                <CloseIcon onClick={toggleMenu} />
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={() => {
                    toggleMenu();
                    document
                      .getElementById("subbody")
                      .scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  aria-current="page"
                  to="/"
                >
                  <span
                    className="gradient-text-hover navHome fw-500"
                    onClick={() => addGradientColorByClass("navHome")}
                  >
                    {navBarContent.home}
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <HashLink
                  className="nav-link"
                  onClick={toggleMenu}
                  scroll={scrollWidthOffset}
                  to="/#features"
                >
                  <span
                    className="gradient-text-hover navFeature fw-500"
                    onClick={() => addGradientColorByClass("navFeature")}
                  >
                    {navBarContent.features}
                  </span>
                </HashLink>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={toggleMenu}
                  // scroll={scrollWidthOffset}
                  to="/search"
                >
                  <span
                    className="gradient-text-hover navNFTs fw-500"
                    onClick={() => addGradientColorByClass("navNFTs")}
                  >
                    {navBarContent.nfts}
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <HashLink
                  className="nav-link"
                  onClick={toggleMenu}
                  scroll={scrollWidthOffset}
                  to="/#guide"
                >
                  <span
                    className="gradient-text-hover navGuide fw-500"
                    onClick={() => addGradientColorByClass("navGuide")}
                  >
                    {navBarContent.guide}
                  </span>
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  className="nav-link"
                  onClick={toggleMenu}
                  scroll={scrollWidthOffset}
                  to="/information"
                >
                  <span
                    className="gradient-text-hover navNews fw-500"
                    onClick={() => addGradientColorByClass("navNews")}
                  >
                    {navBarContent.news}
                  </span>
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  className="nav-link"
                  onClick={toggleMenu}
                  scroll={scrollWidthOffset}
                  to="/#contact"
                >
                  <span
                    className="gradient-text-hover navContact fw-500"
                    onClick={() => addGradientColorByClass("navContact")}
                  >
                    {navBarContent.contact}
                  </span>
                </HashLink>
              </li>
              <li className="nav-item d-lg-none nav_margin">
                <Link
                  className="nav-link text-reset"
                  onClick={toggleMenu}
                  to="/"
                >
                  <LogoImg style={{ width: 186, height: 40, marginTop: 24 }} />
                </Link>
              </li>

              <li className="nav-item d-lg-none nav_margin">
                <HashLink
                  className="nav-link"
                  onClick={toggleMenu}
                  scroll={scrollWidthOffset}
                  to="/privacy"
                >
                  <span
                    className="gradient-text-hover navPrivacy nav_small"
                    onClick={() => addGradientColorByClass("navPrivacy")}
                  >
                    {navBarContent.privacy}
                  </span>
                </HashLink>
              </li>
              <li className="nav-item d-lg-none nav_margin">
                <HashLink
                  className="nav-link"
                  onClick={toggleMenu}
                  scroll={scrollWidthOffset}
                  to="/terms"
                >
                  <span
                    className="gradient-text-hover navTerms nav_small"
                    onClick={() => addGradientColorByClass("navTerms")}
                  >
                    {navBarContent.terms}
                  </span>
                </HashLink>
              </li>
              <li className="nav-item d-lg-none nav_margin">
                <HashLink
                  className="nav-link"
                  onClick={toggleMenu}
                  scroll={scrollWidthOffset}
                  to="/commerce-law"
                >
                  <span
                    className="gradient-text-hover navCommerce nav_small"
                    onClick={() => addGradientColorByClass("navCommerce")}
                  >
                    {navBarContent.commerceLaw}
                  </span>
                </HashLink>
              </li>
              <li className="nav-item d-lg-none pb-5">
                <p className="w-100 nav-link nav_small">
                  &copy;{new Date().getFullYear()} OCTILLION, All rights
                  reserved
                </p>
              </li>
            </ul>
            <AdditionalNavItems
              className="d-none d-lg-block d-xl-block"
              onSearch={onSearch}
              search={search}
              setSearch={setSearch}
              onPopover={handlePopover}
            />
          </div>
          </>}
        </div>
      </nav>
      <div
        id="subbody"
        className={`subbody ${openNoti || openProfile ? "overlay" : ""}`}
      >
        {children}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default AppWrapper;

const DynamicSearchBox = ({ onSearch, setSearch, search }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = document.getElementById("subbody").scrollTop;
    setScrollPosition(position);
  };

  useEffect(() => {
    document.getElementById("subbody").addEventListener("scroll", handleScroll);

    return () => {
      document
        .getElementById("subbody")
        .removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {scrollPosition > 580 && (
        <form
          className="w-25 d-flex d-none d-lg-block d-xl-block ms-3"
          id="mainNavSearch"
          role="search"
        >
          <SearchBox2
            placeholder={translations.textSearchBy}
            iconStyle={{ top: 9 }}
            onSubmit={onSearch}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </form>
      )}
    </>
  );
};

// for mobile
const AdditionalNavItems = (props) => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const [openProfile, setOpenProfile] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [user, setUser] = useState(undefined);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const handleLogoutModalClose = () => {
    console.log("alert close");
    setShowConfirmLogout(false);
  };
  const profileWrapperRef = useRef(null);
  const notiWrapperRef = useRef(null);
  const searchWrapperRef = useRef(null);
  const searchBox3Ref = useRef();
  const customRef = useRef(null);
  useOutsideAlerter(profileWrapperRef, () => {
    setOpenProfile(false);
    setShowConfirmLogout(false);
  });
  useOutsideAlerter(notiWrapperRef, () => {
    setOpenNoti(false);
  });
  useOutsideAlerter(searchWrapperRef, () => {
    setOpenSearch(false);
  });

  const notificationCount = useStoreState("notificationCount");

  const wrapperContent = translations.myPageWrapper;

  const toggleProfile = () => setOpenProfile((open) => !open);
  const toggleNoti = () => setOpenNoti((open) => !open);
  const toggleSearch = () => {
    console.log("click search icon");
    setOpenSearch((open) => !open);
  };

  const getUnreadCount = async () => {
    var response = await getData(`api/notifications/unread-count`);
    if (response.ok) {
      dispatch({
        type: "update",
        key: "notificationCount",
        value: response.data.count,
      });
    }
  };

  const MakeNotificationRead = async () => {
    if (notifications && notifications.length > 0) {
      var response = await postData(`api/notifications/mark-all-read`, {
        ids: notifications.map((x) => x.id),
      });
      if (response.ok) {
        getUnreadCount();
      }
    }
  };

  const getNotifications = async () => {
    var response = await getData(`api/notifications?pagination=5`);
    if (response.ok) {
      setNotifications(response.data.data);
      // MakeNotificationRead();
    }
  };

  const getAccountInfo = async () => {
    var response = await getData(`api/users/user-detail`);
    if (response.ok) {
      setUser(response.data.user);
    }
  };

  useEffect(() => {
    if (getToken()) {
      getNotifications();
      getAccountInfo();
    }

    return () => {
      setNotifications([]);
      setUser(undefined);
    };
  }, []);

  useEffect(() => {
    if (openNoti) {
      MakeNotificationRead();
    }
  }, [openNoti]);

  useEffect(() => {
    props.onPopover(openNoti, openProfile);
  }, [openNoti, openProfile]);

  const onCloseSearch = () => {
    props.setSearch("");
    if (searchBox3Ref && searchBox3Ref.current) {
      searchBox3Ref.current.focus();
    }
  };

  return (
    <div className={props.className || ""}>
      <ul className={`navbar-nav ml-auto flex-row flex-center`}>
        <li className="nav-item d-lg-none" ref={searchWrapperRef}>
          <a className="nav-link custom-popover" onClick={() => toggleSearch()}>
            <SearchIcon style={{ fillOpacity: 1 }} />
          </a>
          {openSearch && (
            <div className="popover-container search-popover">
              <div className="body">
                <form className="d-flex" role="search">
                  <SearchBox3
                    ref={searchBox3Ref}
                    placeholder={translations.textSearchBy}
                    onSubmit={(e) => {
                      props.onSearch(e);
                      toggleSearch();
                    }}
                    onChange={(e) => props.setSearch(e.target.value)}
                    value={props.search}
                    onClose={onCloseSearch}
                    toggleSearch={toggleSearch}
                  />
                  {/* <CloseIcon
                    className="btn-custom-close"
                    onClick={() => toggleSearch()}
                  /> */}
                </form>
              </div>
            </div>
          )}
        </li>
        {getToken() && (
          <li className="nav-item mx-3" ref={notiWrapperRef}>
            <a
              className="nav-link custom-popover badge-wrapper"
              onClick={() => toggleNoti()}
            >
              <BellIcon />
              {notificationCount && notificationCount > 0 ? (
                <span className="badge badge-custom">{notificationCount}</span>
              ) : (
                <></>
              )}
            </a>
            {openNoti && (
              <div className="popover-container noti">
                <div className="body">
                  <div className="row d-flex">
                    <div className="col-12">
                      <h3 className="fw-500 italic mb-5">Notifications</h3>
                    </div>
                  </div>
                  <CloseIcon
                    className="btn-custom-close"
                    onClick={() => toggleNoti()}
                  />
                  <div className="row">
                    {notifications &&
                      notifications.map((notification, index) => (
                        <div key={index} className="col-12">
                          <Link
                            to={
                              notification.trade_id
                                ? `/mypage/trade/detail/${notification.trade_id}`
                                : notification.order_id
                                ? `/mypage/send-offer`
                                : "#"
                            }
                            onClick={toggleNoti}
                            className="text-reset text-decoration-none"
                          >
                            <div className="d-flex mb-3">
                              <div className="pill">
                                <span className="notice">
                                  {"運営からのお知らせ"}
                                </span>
                              </div>
                              <span className="ms-2 date">
                                {moment(notification.created_at).format(
                                  "yyyy/M/D"
                                )}
                              </span>
                            </div>
                            <p>{notification.text}</p>
                          </Link>
                          <div className="divider mb-3"></div>
                        </div>
                      ))}
                    <div className="col-12 d-flex flex-row w-100 justify-content-end align-items-center pt-3 pt-lg-0">
                      <Link
                        className="nav-link d-flex flex-row justify-content-lg-end mb-0 pull-right text-white w-100"
                        aria-current="page"
                        to={`/notification`}
                        onClick={toggleNoti}
                      >
                        <p
                          className="title mb-0 me-2"
                          style={{ marginTop: -2 }}
                        >
                          view more
                        </p>
                        <PlusCircleIcon style={{ fill: "#FFF" }} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </li>
        )}
        <li
          className="nav-item d-flex align-items-center"
          ref={showConfirmLogout ? customRef : profileWrapperRef}
        >
          {!getToken() ? (
            <Button
              variant={6}
              onClick={() => navigate("/login")}
              className="navLoginBtn"
            >
              {"ログイン/会員登録"}
            </Button>
          ) : (
            <>
              <a
                className="nav-link custom-popover"
                onClick={() => toggleProfile()}
              >
                <UserIcon />
              </a>
              {openProfile && (
                <div className="popover-container">
                  <div className="body">
                    <div className="row d-flex">
                      <div className="col-lg-12 col-12">
                        <h3 className="fw-500 italic">USER ACCOUNT</h3>
                      </div>
                    </div>
                    <CloseIcon
                      className="btn-custom-close"
                      onClick={() => toggleProfile()}
                    />
                    <div className="row mt-3">
                      <div className="col-12 text-center">
                        {user && user.image ? (
                          <img
                            className="profile-pic mb-2"
                            src={user.image}
                            style={{ width: 80, height: 80 }}
                            alt=""
                          />
                        ) : (
                          <DefaultUserImg
                            className="profile-pic mb-2"
                            style={{ width: 80, height: 80 }}
                          />
                        )}
                      </div>
                      <div className="col-12 text-center">
                        <span className="fw-400">
                          {user ? user.accountName : ""}
                        </span>
                      </div>
                      <div className="col-12 text-center py-3">
                        <Button
                          className="d-none d-sm-inline"
                          variant={4}
                          onClick={() => {
                            toggleProfile();
                            navigate("/mypage/account");
                          }}
                        >
                          <p className="mb-0">
                            {wrapperContent.accountDetailButton}
                          </p>
                        </Button>

                        <Button
                          className="d-sm-none pointer"
                          variant={4}
                          onClick={() => {
                            toggleProfile();
                            navigate("/mypage/account");
                          }}
                        >
                          <p className="mb-0">
                            {wrapperContent.accountDetailButton}
                          </p>
                        </Button>
                      </div>
                    </div>
                    <div className="px-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex flex-column">
                            <span className="text-center mb-2">
                              {wrapperContent.walletLabel}
                            </span>
                            {getWalletAddress() ? (
                              <div
                                className="d-flex flex-row align-items-center"
                                style={{
                                  border: "1px solid #FFF",
                                  borderRadius: 10,
                                  padding: "10px 17px",
                                }}
                              >
                                <span
                                  className="text-center text-break me-2"
                                  style={{ fontSize: 15 }}
                                >
                                  {getWalletAddress()}
                                </span>
                                <CopyIcon
                                  onClick={() =>
                                    copyToClipboard(getWalletAddress())
                                  }
                                  className="pointer"
                                />
                              </div>
                            ) : (
                              <span
                                className="pointer text-center"
                                onClick={() => {
                                  toggleProfile();
                                  navigate("/login/anicana");
                                }}
                              >
                                METAMASKでログイン
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-4"></div>
                        <div className="row d-flex flex-row">
                          <div
                            className="accordion "
                            // className="accordion col-8 col-sm-12"
                            id="profile-accordian"
                            style={{ padding: 10 }}
                          >
                            <div className="accordion-item ">
                              <h4
                                className="accordion-header"
                                id="マイトークン"
                              >
                                <a
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#マイトークン-body"
                                  aria-expanded="true"
                                  aria-controls="マイトークン-body"
                                >
                                  {wrapperContent.subtitle.myTokens}
                                </a>
                              </h4>
                              {/* Start */}
                              <div
                                id="マイトークン-body"
                                className="accordion-collapse collapse"
                                aria-labelledby="マイトークン"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/owned-arcana");
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        {/* Mobile */}
                                        <ArcanaMobileIcon
                                          className="d-block d-sm-none me-1"
                                          style={{ width: 20, height: 20 }}
                                        />
                                        {/* Pc */}
                                        <ArcanaIcon
                                          white='true'
                                          className="d-none d-sm-block"
                                        />
                                        <span className="ms-1">
                                          {wrapperContent.list.holdingArcana}
                                        </span>
                                      </div>
                                    </li>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/owned-persona");
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <Word_PIcon
                                          className="d-block d-sm-none me-1"
                                          style={{ width: 20, height: 20 }}
                                        />
                                        <PersonaIcon
                                          white='true'
                                          className="d-none d-sm-block"
                                        />
                                        <span className="ms-1">
                                          {wrapperContent.list.holdingPersona}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h4 className="accordion-header" id="取引">
                                <a
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#取引-body"
                                  aria-expanded="true"
                                  aria-controls="取引-body"
                                >
                                  {wrapperContent.subtitle.transaction}
                                </a>
                              </h4>
                              {/* Start */}
                              <div
                                id="取引-body"
                                className="accordion-collapse collapse"
                                aria-labelledby="取引"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/buy-transactions");
                                      }}
                                    >
                                      <div>
                                        <BuyIcon />
                                        <span className="ms-1">
                                          {wrapperContent.list.buyTransactions}
                                        </span>
                                      </div>
                                    </li>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/sell-transactions");
                                      }}
                                    >
                                      <div>
                                        <SellIcon />
                                        <span className="ms-1">
                                          {wrapperContent.list.sellTransactions}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h4 className="accordion-header" id="オファー">
                                <a
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#オファー-body"
                                  aria-expanded="true"
                                  aria-controls="オファー-body"
                                >
                                  {wrapperContent.subtitle.offer}
                                </a>
                              </h4>
                              {/* Start */}
                              <div
                                id="オファー-body"
                                className="accordion-collapse collapse"
                                aria-labelledby="オファー"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/receive-offer");
                                      }}
                                    >
                                      <div>
                                        <BidIcon />
                                        <span className="ms-1">
                                          {wrapperContent.list.incomingOffers}
                                        </span>
                                      </div>
                                    </li>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/send-offer");
                                      }}
                                    >
                                      <div>
                                        <AskIcon />
                                        <span className="ms-1">
                                          {wrapperContent.list.outgoingOffers}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* Start */}
                              <h4 className="accordion-header" id="保存">
                                <a
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#保存-body"
                                  aria-expanded="true"
                                  aria-controls="保存-body"
                                >
                                  {wrapperContent.subtitle.save}
                                </a>
                              </h4>
                              {/* Start */}
                              <div
                                id="保存-body"
                                className="accordion-collapse collapse"
                                aria-labelledby="保存"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/bookmarks");
                                      }}
                                    >
                                      <div>
                                        <BookmarkIcon style={{ width: 19 }} />
                                        <span className="ms-1">
                                          {wrapperContent.list.bookmark}
                                        </span>
                                      </div>
                                    </li>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/history");
                                      }}
                                    >
                                      <div>
                                        <HistoryIcon style={{ width: 19 }} />
                                        <span className="ms-1">
                                          {wrapperContent.list.browsingHistory}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h4 className="accordion-header" id="設定">
                                <a
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#設定-body"
                                  aria-expanded="true"
                                  aria-controls="設定-body"
                                >
                                  {wrapperContent.subtitle.establishment}
                                </a>
                              </h4>
                              {/* Start */}
                              <div
                                id="設定-body"
                                className="accordion-collapse collapse"
                                aria-labelledby="設定"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/account");
                                      }}
                                    >
                                      <div>
                                        <FunctionIcon />
                                        <span className="ms-1">
                                          {wrapperContent.list.account}
                                        </span>
                                      </div>
                                    </li>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/payment");
                                      }}
                                    >
                                      <div>
                                        <PaymentIcon />
                                        <span className="ms-1">
                                          {
                                            wrapperContent.list
                                              .receivingAccountInformation
                                          }
                                        </span>
                                      </div>
                                    </li>
                                    <li
                                      onClick={() => {
                                        toggleProfile();
                                        navigate("/mypage/setting");
                                      }}
                                    >
                                      <div>
                                        <SettingIcon />
                                        <span className="ms-1">
                                          {wrapperContent.list.functionSettings}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end justify-content-lg-center align-items-end ">
                            <div
                              className="pointer"
                              onClick={() => {
                                setShowConfirmLogout(true);
                              }}
                            >
                              <LogoutIcon style={{ width: 13 }} />
                              <span className="ms-1">ログアウト</span>
                            </div>
                          </div>
                        </div>

                        {/* <div
                          className="d-flex justify-content-center"
                          // className="d-flex justify-content-end justify-content-sm-center"
                          style={{ backgroundColor: "red" }}
                        >
                          <div className="logout">
                            <a
                              className={
                                "d-flex align-items-center subitem text-reset text-decoration-none"
                              }
                              onClick={() => {
                                setShowConfirmLogout(true);
                              }}
                            >
                              <div>
                                <LogoutIcon style={{ width: 13 }} />
                                <span className="ms-1">ログアウト</span>
                              </div>
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <ConfirmLogoutModal
                    isOpen={showConfirmLogout}
                    onRequestClose={handleLogoutModalClose}
                  ></ConfirmLogoutModal>
                </div>
              )}
            </>
          )}
        </li>
        {/* {
                    (getToken()) &&
                    <li className="nav-item">
                        <Link className="nav-link" to="/">
                            <WalletIcon />
                        </Link>
                    </li>
                } */}
      </ul>
    </div>
  );
};

const ConfirmLogoutModal = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();

  console.log("alert is working");

  const [loading, setLoading] = useState(false);

  const signout = async () => {
    setLoading(true);
    logout();
    setLoading(false);
    onRequestClose();
    logout(() => {
      window.location.href = "/";
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} lg>
      <div className="container-fluid selling-price-confirmation text-center">
        <h4 className="mb-5">{translations.textLogout}</h4>
        <div style={{ fontSize: "25px" }}>
          本当にログアウトしてもよろしいですか？
        </div>
        <div className="row btns">
          <div className="col-6">
            <Button variant={2} onClick={onRequestClose} className="w-100">
              {translations.textBack}
            </Button>
          </div>
          <div className="col-6">
            <Button
              variant={3}
              loading={loading}
              onClick={() => signout()}
              className="w-100"
            >
              {translations.textLogout}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
