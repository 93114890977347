import LocalizedStrings from 'react-localization'
import initialLanguageStore from './localizationmain'
import { getLanguage } from '../services/storage';

export let translations = new LocalizedStrings(initialLanguageStore);


export const onSetLanguageToEnglish = () => {
    translations.setLanguage('en');
}
export const onSetLanguageToJapanese = () => {
    translations.setLanguage('jp');
}

if (getLanguage()) {
    if (getLanguage() == 'en') {
        onSetLanguageToEnglish();
    } else {
        onSetLanguageToJapanese();
    }
} else {
    onSetLanguageToJapanese();
}
