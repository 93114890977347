/* eslint-disable import/no-anonymous-default-export */
export default {
  test: {
    title: "Anikana Japan",
  },

  arcana: "アルカナ",
  persona: "ペルソナ",

  warning_messages: {
    changeLanguage: "言語を変更してもよろしいですか？",
  },

  success_messages: {
    textCopy: "クリップボードにコピーしました",
  },

  emptylist_messages: {
    noTradingHistory: "取引履歴はありません",
    noOrderHistory: "取引履歴はありません",
  },

  ascendingOrder: "古い順",
  descendingOrder: "新しい順",
  textCount: "件",
  textSearchBy: "トークン名/作成者名で検索",

  textCancel: "キャンセル",
  textDelete: "退会",
  textBack: "戻る",
  textLogout: "ログアウト",

  navBar: {
    home: "ホーム",
    features: "特徴",
    nfts: "NFTs",
    guide: "ガイド",
    news: "ニュース",
    contact: "コンタクト",
    privacy: "プライバシーポリシー",
    terms: "利用規約",
    commerceLaw: "特定商取引法に基づく表示",
  },

  footer: {
    home: "ホーム",
    features: "特徴",
    nfts: "NFTs",
    guide: "ガイド",
    news: "ニュース",
    contact: "コンタクト",
    privacy: "プライバシーポリシー",
    terms: "利用規約",
    commerceLaw: "特定商取引法に基づく表示",
    policy: "Privacy Policy",
    tearm_of_use: "Terms of Use",
  },

  home: {
    newArrivals: {
      title: "新着",
      viewMore: "もっと見る",
    },

    features: {
      title: "特徴",
    },

    guide: {
      title: "ガイド",
    },

    faq: {
      title: "FAQ",
    },
    contact: {
      title: "お問い合わせ",
      labelName: "お名前",
      labelEmail: "メールアドレス",
      labelMessage: "内容",
      placeHolderName: "お名前を入力してください",
      placeHolderEmail: "メールアドレスを入力してください",
      placeHolderMessage: "質問を入力してください",
      buttonSend: "送信",
    },
  },

  news: {
    title: "お知らせ",
    buttonAll: "全て",
  },

  nftSearch: {
    list: "一覧",
    sideBarWrapper: {
      title: "詳細検索",
      category: "Category（カテゴリ)",
      basicInformation: "基本情報",
      score: "スコア",
      availability: "販売状況",
      onSale: "販売中",
    },
  },

  myPageWrapper: {
    title: "MY PAGE",
    accountDetailButton: "アカウント詳細・編集",
    walletLabel: "アニカナウォレット",
    subtitle: {
      myTokens: "マイトークン",
      transaction: "取引",
      offer: "オファー",
      save: "保存",
      establishment: "設定",
    },
    list: {
      holdingArcana: "保有アルカナ",
      buyTransactions: "買い取引",
      sellTransactions: "売り取引",
      incomingOffers: "受信オファー",
      outgoingOffers: "送信オファー",
      bookmark: "ブックマーク",
      browsingHistory: "閲覧履歴",
      account: "アカウント",
      receivingAccountInformation: "受け取り口座情報",
      functionSettings: "機能設定",
    },
  },

  setting: {
    title: "機能設定",
    subtitle: {
      notificationFunction: "通知機能",
      language: "言語",
    },
    label: {
      executionNotice: "約定通知",
      transactionFailureNotification: "取引失敗通知",
      language: "言語",
    },
  },

  account: {
    label: {
      walletAddress: "ウォレットアドレス",
      userNo: "ユーザNo",
      emailAddress: "メールアドレス",
      phoneNumber: "携帯電話番号",
      password: "パスワード",
    },
    buttonAccountDelete: "退会する",
    infoEmail: {
      title: "メールアドレス更新",
      labelEmail: "新しいメールアドレス",
      placeHolderEmail: "メールアドレスを入力してください",
      buttonSendEmail: "メールを送信する",
      labelCode: "コード",
      placeHolderConfirmCode: "Enter your code",
      buttonConfirm: "確定",
    },
    contact: {
      title: "お問い合わせ",
      labelName: "お名前",
      labelEmail: "メールアドレス",
      labelMessage: "内容",
      placeHolderName: "お名前を入力してください",
      placeHolderEmail: "メールアドレスを入力してください",
      placeHolderMessage: "質問を入力してください",
      buttonSend: "送信",
    },
  },

  news: {
    title: "お知らせ",
    buttonAll: "全て",
  },

  nftSearch: {
    list: "一覧",
    sideBarWrapper: {
      title: "詳細検索",
      category: "Category（カテゴリ)",
      basicInformation: "基本情報",
      score: "スコア",
      availability: "販売状況",
      onSale: "販売中",
    },
  },

  myPageWrapper: {
    title: "MY PAGE",
    accountDetailButton: "アカウント詳細・編集",
    walletLabel: "アニカナウォレット",
    subtitle: {
      myTokens: "マイトークン",
      transaction: "取引",
      offer: "オファー",
      save: "保存",
      establishment: "設定",
    },
    list: {
      holdingArcana: "保有アルカナ",
      holdingPersona: "保有ペルソナ",
      buyTransactions: "買い取引",
      sellTransactions: "売り取引",
      incomingOffers: "受信オファー",
      outgoingOffers: "送信オファー",
      bookmark: "ブックマーク",
      browsingHistory: "閲覧履歴",
      account: "アカウント",
      receivingAccountInformation: "受け取り口座情報",
      functionSettings: "機能設定",
    },
  },

  setting: {
    title: "機能設定",
    subtitle: {
      notificationFunction: "通知機能",
      language: "言語",
    },
    label: {
      executionNotice: "約定通知",
      transactionFailureNotification: "取引失敗通知",
      language: "言語",
    },
  },

  account: {
    label: {
      walletAddress: "ウォレットアドレス",
      userNo: "ユーザ",
      emailAddress: "メールアドレス",
      phoneNumber: "携帯電話番号",
      password: "パスワード",
    },
    buttonAccountDelete: "退会する",
    infoEmail: {
      title: "メールアドレス更新",
      labelEmail: " 新しいメールアドレス",
      placeHolderEmail: "メールアドレスを入力してください",
      buttonSendEmail: "メールを送信する",
      labelCode: "コード",
      placeHolderConfirmCode: "Enter your code",
      buttonConfirm: "確定",
    },
    infoPhone: {
      title: "携帯電話番号更新",
      labelPhone: "新しい携帯電話番号",
      placeHolderPhone: "電話番号を入力してください",
      buttonSendSMS: "SMSを送信する",
      labelCode: "コード",
      placeHolderConfirmCode: "Enter your code",
      buttonConfirm: "確定",
    },
    infoPassword: {
      title: "パスワードの変更",
      labelCurrentPassword: "現在のパスワード",
      labelNewPassword: "新規",
      labelConfirmPassword: "確認",
      placeHolderCurrentPassword: "現在のパスワードを入力してください",
      placeHolderNewPassword: "新しいパスワードを入力してください",
      placeHolderConfirmPassword: "新しいパスワードを入力してください",
      buttonResetPassword: "パスワードの再設定",
    },
    infoAccountName: {
      title: "ユーザーの更新",
      labelAccountName: "アカウント名",
      placeHolderAccountName: "アカウント名を入力してください",
      buttonConfirm: "確定",
    },
    infoDelete: {
      title: "退会",
      labelDescription: "差支えなければ退会理由をお聞かせください",
      titleNote: "注意事項",
      textNote1:
        "利用者は、本サービスの退会を希望する場合、当社所定の手続きを行うことにより退会できます。",
      textNote2:
        "利用者が本サービスから退会する場合、当社は利用者情報等のデータ保管義務を負いません。",
      textNote3:
        "利用者が退会した時点をもって、利用契約は終了するものとします。",
      labelAgree: "注意事項に同意する",
      buttonDelete: "アカウントを完全に削除する",
      textModal: "退会処理を行います。よろしいですか？",
    },
  },

  payment: {
    title: "受け取り口座情報",
    label: {
      bankName: "金融機関名",
      bankCode: "金融機関コード",
      branchName: "支店名",
      branchCode: "支店コード",
      accountType: "口座種類",
      accountNumber: "口座番号",
      accountHolderName: "口座名義(カナ)",
    },
    buttonEdit: "編集",
    buttonCancel: "キャンセル",
    buttonSave: "保存",
  },
};
