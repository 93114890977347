import React, { useState } from "react";
import "../assets/scss/item.scss";
import ArcanaSVG from "../assets/icons/arcana_icon.svg";
import PersonaSVG from "../assets/icons/persona_icon.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/carousel_left_arrow.svg";
import { ReactComponent as RightArrow } from "../assets/icons/carousel_right_arrow.svg";
import arcanaImage from "../assets/images/arcana_img.jpg";
import personaImage from "../assets/images/persona_img.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const MobileFirstView = () => {
  const [index, setIndex] = useState(0);
  const length = 2;

  const handlePrevious = (id) => {
    document
      .getElementById("first-view-crousel-" + id)
      .classList.toggle("slide-left");
    setTimeout(() => {
      document
        .getElementById("first-view-crousel-" + id)
        .classList.remove("slide-left");
      const newIndex = index - 1;
      setIndex(newIndex < 0 ? length - 1 : newIndex);
    }, 800);
  };

  const handleNext = (id) => {
    document
      .getElementById("first-view-crousel-" + id)
      .classList.toggle("slide-right");
    // setTimeout(() => {
    //   document
    //     .getElementById("first-view-crousel-" + id)
    //     .classList.remove("slide-right");
    //   const newIndex = index + 1;
    //   setIndex(newIndex >= length ? 0 : newIndex);
    // }, 800);
  };

  const carousel_items = [
    {
      id: 1,
      image_src: arcanaImage,
      name: "ARCANAS",
      is_coming_soon: false,
      icon: ArcanaSVG,
    },
    {
      id: 2,
      image_src: personaImage,
      name: "PERSONAS",
      is_coming_soon: true,
      icon: PersonaSVG,
    },
  ];

  // not neec
  const Carousedddl = () => {
    return (
      <div className="carousel first-view-mobile-crousel">
        <div
          id={`first-view-crousel-${carousel_items[index].id}`}
          className="opacity-transition"
        >
          <img
            className={
              carousel_items[index].is_coming_soon
                ? "opacity-50 opacity-transition"
                : "opacity-transition"
            }
            src={carousel_items[index].image_src}
            style={{ width: "100%", maxHeight: 400, objectFit: "cover" }}
          />
          {carousel_items[index].is_coming_soon ? (
            <div className="coming-soon-text display-1 fw-600 text-rainbow text-overflow">
              Coming Soon
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex justify-content-around mt-4">
          <LeftArrow
            className="mt-3"
            onClick={() => handlePrevious(carousel_items[index].id)}
          />
          <div className="text-center">
            {/* <div>{carousel_items[index].icon}</div> */}
            <img src={carousel_items[index].icon} style={{ height: 50 }} />
            <div className="fw-600 h4 mt-2">{carousel_items[index].name}</div>
          </div>
          <RightArrow
            className="mt-3"
            onClick={() => handleNext(carousel_items[index].id)}
          />
        </div>
      </div>
    );
  };
  // Fix
  const EachCarousel = ({ item }) => {
    return (
      <div style={{ width: "100%" }}>
        <img
          className={
            item.is_coming_soon
              ? "opacity-50 opacity-transition"
              : "opacity-transition"
          }
          src={item.image_src}
          style={{ width: "100%", maxHeight: 400, objectFit: "cover" }}
        />
        {item.is_coming_soon ? (
          <div className="coming-soon-text display-1 fw-600 text-rainbow text-overflow">
            Coming Soon
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return (
      <div className="carousel-button-group">
        <div className="d-flex justify-content-around mt-4">
          <LeftArrow className="mt-3" onClick={() => previous()} />
          <div className="text-center">
            <img
              src={
                currentSlide &&
                carousel_items[
                  currentSlide - 2 === 2
                    ? 0
                    : currentSlide - 2 === -1
                    ? 1
                    : currentSlide - 2 === -2
                    ? 0
                    : currentSlide - 2
                ].icon == 0
                  ? carousel_items[0].icon
                  : carousel_items[
                      currentSlide - 2 === 2
                        ? 0
                        : currentSlide - 2 === -1
                        ? 1
                        : currentSlide - 2 === -2
                        ? 0
                        : currentSlide - 2
                    ].icon
              }
              style={{ height: 50 }}
            />
            <div className="fw-600 h4 mt-2">
              {currentSlide &&
              carousel_items[
                currentSlide - 2 === 2
                  ? 0
                  : currentSlide - 2 === -1
                  ? 1
                  : currentSlide - 2 === -2
                  ? 0
                  : currentSlide - 2
              ].name == 0
                ? carousel_items[0].name
                : carousel_items[
                    currentSlide - 2 === 2
                      ? 0
                      : currentSlide - 2 === -1
                      ? 1
                      : currentSlide - 2 === -2
                      ? 0
                      : currentSlide - 2
                  ].name}
            </div>
          </div>
          <RightArrow className="mt-3" onClick={() => next()} />
        </div>
      </div>
    );
  };

  return (
    <div className="mb-5">
      <div className="h1 fw-600 mt-4 text-center">
        An <span className="title text-rainbow">NFT</span> Marketplace,
      </div>
      <div className="h1 fw-600  mb-4 text-center">
        a new mobile <span className="title text-rainbow">Experience!</span>{" "}
      </div>

      <div className="carousel first-view-mobile-crousel">
        <Carousel
          responsive={responsive}
          infinite={true}
          keyBoardControl={true}
          showDots={false}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          transitionDuration={400}
        >
          {carousel_items.map((item, index) => (
            <EachCarousel key={index} item={item} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};
