import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "../components/Swal";
import { fetchData, getData, postData } from "../services/fetch";
import { useDidUpdateEffect } from "../services/hook";
import { clearLocalStorage, getValue, setTempToken, setToken, setValue, setUserId } from "../services/storage";

import { Web3authContext } from "./web3authContext";

export const AuthContext = createContext(); // could have a default value

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { setIsDoneIDMS, setIsDoneMetamask, setWallet_address, web3Logout } = useContext(Web3authContext);
    const [user, setUser] = useState(null);

    const loadUser = async () => {
        const user = await getValue('user_info');
        setUser(user);
    }

    useEffect(() => {
        loadUser();

        return () => {
            setUser(null);
        }
    }, [])

    useDidUpdateEffect(() => {
        setValue('user_info', user);
    }, [user])


    const login = async ({ email, password }) => {
        var response = await fetchData(`api/login`, {
            email, password
        }, 'post', true);
        if (response.ok) {
            if (response.data) {
                const { access_token, isDoneIDMS, isDoneMetamask, wallet_address, user } = response.data;
                if (response.data.response && response.data.response.errorMessage) {
                    Swal.fire({
                        icon: 'error',
                        title: 'エラーが発生しました',
                        text: response.data.response.errorMessage,
                    })
                } else {
                    setUserId(user.id);
                    setIsDoneIDMS(isDoneIDMS);
                    setIsDoneMetamask(isDoneMetamask);
                    setTempToken(access_token);
                    if (isDoneIDMS && isDoneMetamask) {
                        setToken(access_token);
                        setTempToken('');
                        setWallet_address(wallet_address);
                        setUser(user);
                        navigate('/');
                    } else {
                        navigate('/register');
                    }
                }
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: response.data.message || response.data,
            })
        }
    }

    const logout = async (func = undefined) => {
        try {
            setUser({});
            clearLocalStorage();
            await web3Logout();
            if (func) {
                func();
            } else {
                window.location.href = "/";
            }
        } catch (e) {
            console.log('logout error', e.message);
            window.location.href = "/";
        }
    }

    const refreshUser = async () => {
        const response = await getData(`api/users/user-detail`);
        if (response.ok) {
            setUser(response.data.user);
        }
    }

    const value = useMemo(() => ({
        user, setUser, login, logout, refreshUser
    }), [user]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
