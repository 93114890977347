import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  SelectWithLabel,
  TextBoxWithLabel,
} from "../../components/Form";
import MyPageWrapper from "../../components/MyPageWrapper";
import Switch from "../../components/Switch";
import { getData, postData } from "../../services/fetch";
import { useDidUpdateEffect } from "../../hooks/useDidUpdateEffect";
import {
  onSetLanguageToEnglish,
  onSetLanguageToJapanese,
} from "../../services/localization";
import Modal from "../../components/Modal";
import { setLanguage, getLanguage } from "../../services/storage";
import { translations } from "../../services/localization";

const FunctionSetting = () => {
  const [loading, setLoading] = useState(false);
  const [match_notification_flg, setMatch_notification_flg] = useState(0);
  const [
    change_trade_status_notification_flg,
    setChange_trade_status_notification_flg,
  ] = useState(0);
  const [information_notification_flg, setInformation_notification_flg] =
    useState(0);
  const [settingLanguage, setSettingLanguage] = useState(
    getLanguage() ? getLanguage() : "jp"
  );
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const content = translations.setting;

  useEffect(() => {
    getSetting();
  }, []);

  useDidUpdateEffect(() => {
    if (!loading) {
      saveSetting();
    }
  }, [
    match_notification_flg,
    change_trade_status_notification_flg,
    information_notification_flg,
  ]);

  const getSetting = async () => {
    setLoading(true);
    var response = await getData(`api/users/get-user-setting-flags`);
    if (response.ok) {
      const {
        match_notification_flg,
        change_trade_status_notification_flg,
        information_notification_flg,
      } = response.data.user_setting || {
        match_notification_flg: 0,
        change_trade_status_notification_flg: 0,
        information_notification_flg: 0,
      };
      setMatch_notification_flg(match_notification_flg);
      setChange_trade_status_notification_flg(
        change_trade_status_notification_flg
      );
      setInformation_notification_flg(information_notification_flg);
      setLoading(false);
    }
  };

  const saveSetting = async () => {
    var response = await postData(`api/users/set-user-setting-flags`, {
      match_notification_flg,
      change_trade_status_notification_flg,
      information_notification_flg,
    });
  };

  const changeLanguage = (value) => {
    if (value == "en") {
      onSetLanguageToEnglish();
    } else {
      onSetLanguageToJapanese();
    }
    setIsOpen(false);
    setLanguage(value);
    navigate("/mypage/setting");
  };

  return (
    <MyPageWrapper disabledSubNav pageid="機能設定">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex flex-column align-items-start mb-5">
              <h3 className="text-start">{content.title}</h3>
            </div>
          </div>
        </div>
        <div className="row ms-3">
          <div className="col-12 col-md-6 col-xl-5 col-xxl-4 px-0 mb-4">
            <h4 className="fw-400 text-start">
              {content.subtitle.notificationFunction}
            </h4>
            <div className="divider"></div>
          </div>
          <div className="col-12 px-0">
            <label className="mb-3">
              <span className="fw-400">{content.label.executionNotice}</span>
              <Switch
                onChange={(checked) =>
                  setMatch_notification_flg(checked ? 1 : 0)
                }
                checked={match_notification_flg == 1 ? true : false}
              />
            </label>
            <label className="mb-3">
              <span className="fw-400">
                {content.label.transactionFailureNotification}
              </span>
              <Switch
                onChange={(checked) =>
                  setChange_trade_status_notification_flg(checked ? 1 : 0)
                }
                checked={
                  change_trade_status_notification_flg == 1 ? true : false
                }
              />
            </label>
            {/* <label className='mb-3'>
                            <span className='fw-400'>お知らせ通知</span>
                            <Switch onChange={checked => setInformation_notification_flg(checked ? 1 : 0)} checked={information_notification_flg == 1 ? true : false} />
                        </label> */}
          </div>
        </div>

        {/* Language */}
        {/* <div className="row ms-3 mt-4">
          <div className="col-12 col-md-6 col-xl-5 col-xxl-4 px-0 mb-4">
            <h4 className="fw-400 text-start">{content.subtitle.language}</h4>
            <div className="divider"></div>
          </div>
          <div className="col-12 px-0">
            <SelectWithLabel
              labelWidth={1}
              className="sort"
              style={{ width: 133 }}
              label={content.label.language}
              options={[
                { value: "en", text: "English" },
                { value: "jp", text: "Japanese" },
              ]}
              value={settingLanguage}
              onChange={(e) => {
                setSettingLanguage(e.target.value);
                setIsOpen(true);
              }}
            />
          </div>
        </div> */}
        <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
          <h3 className="modal-title">キャンセル</h3>
          <span className="modal-text">
            {translations.warning_messages.changeLanguage}
          </span>
          <div className="row my-4 px-5">
            <div className="col-6">
              <Button
                variant={2}
                onClick={() => {
                  setIsOpen(false);
                  setSettingLanguage(getLanguage());
                }}
                className="btn-hover w-100"
              >
                No
              </Button>
            </div>
            <div className="col-6">
              <Button
                variant={3}
                onClick={() => changeLanguage(settingLanguage)}
                className="w-100"
              >
                <span className="w-100">Yes</span>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </MyPageWrapper>
  );
};

export default FunctionSetting;
