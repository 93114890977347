const categories = [
    { id: 1, name: 'NFTについて' },
    { id: 2, name: 'Walletについて' },
    { id: 3, name: 'Marketplaceについて' },
    { id: 4, name: 'Blockchainについて' },
]

const faqs = [
    {
        question: `NFTとは何ですか？	`,
        answer: `Non-Fungible Token（ノンファンジブルトークン）の略で、安全にデータを記録できる技術であるブロックチェーン上で発行される、代替不可能なデジタルトークンです。一つ一つのトークン自体が固有の値や属性を持つ、替えが効かない唯一無二のものをいいます。`,
        category_id: 1,
    },
    {
        question: `NFTはどのように機能しますか？	`,
        answer: `NFTはブロックチェーン技術で動作します。ブロックチェーンは基本的に、大規模なデジタルの公的記録です。最も人気のあるブロックチェーンは多くのノードに分散されているため、「分散型」と呼ばれることがあります。誰かがNFT作成、譲渡、購入、販売、またはその他の方法で行うと、そのすべてがブロックチェーンに記録されます。これにより、認証が可能になります。`,
        category_id: 1,
    },
    {
        question: `NFTは何に使用されますか？	`,
        answer: `NFTは何でも表すことができます。暗号通貨取引に興味がある人やアート作品を収集するのが好きな人は、NFTをよく使用します。デジタルコンテンツ、アート、ゲームアイテム、ドメイン名、チケットとメンバーシップ、投資と担保などです。`,
        category_id: 1,
    },
    {
        question: `PERSONA（ペルソナ）とは何ですか？	`,
        answer: `PERSONA（ペルソナ）は、アニカナ上で特定のスマートコントラクトを発動させる触媒のような役割を持ったNFTです。`,
        category_id: 1,
    },
    {
        question: `ARCANA（アルカナ）とは何ですか？	`,
        answer: `ARCANA（アルカナ）とは、アニカナ上で発行されるNFTです。アルカナにはトレーディングカードのような見た目と様々な属性/内部値（Score）と性質（機能）を持たせることができます。`,
        category_id: 1,
    },
    {
        question: `PERSONA（ペルソナ）とARCANA（アルカナ）をどこに手に入りますか？	`,
        answer: `PERSONAとARCANAは様々なゲームの中で購入アイテムとして取得するか、ゲームで遊んだ結果として自分で生成します。`,
        category_id: 1,
    },
    {
        question: `クリプトウォレットとは何ですか？	`,
        answer: `クリプトウォレットは、暗号通貨と (多くの場合) NFT の購入、販売、保管を支援するプログラムです。 ブロックチェーン上のアドレスと考えてください。そこからアイテムを送受信したり、アイテムを保管したりできます。`,
        category_id: 2,
    },
    {
        question: `Anicana Wallet（アニカナウォレット）とは何ですか？	`,
        answer: `「アニカナウォレット」とは、ブロックチェーン（ANICANA Chain）上のNFTを含むトークンを格納するためのウォレットをいいます。任意にトークンを出し入れすることができ、本サービスを利用するにあたって必要となります。`,
        category_id: 2,
    },
    {
        question: `複数のクリプトウォレットを持つことはできますか？	`,
        answer: `できません。現在Octillionに接続可能なウォレットはアニカナウォレットのみです。`,
        category_id: 2,
    },
    {
        question: `Octillionがサポートするクリプトウォレットは何ですか？	`,
        answer: `現在Octillionがサポートするウォレットはアニカナウォレットのみです。`,
        category_id: 2,
    },
    {
        question: `LEVICAとは何ですか？	`,
        answer: `レヴィアス株式会社が提供している前払式支払手段・電子マネーです。`,
        category_id: 2,
    },
    {
        question: `Octillion（オクティリオン）とは？	`,
        answer: `Web3.0による独自の分散型システム【ANICANA（アニカナ）チェーン】上で発行されるNFT等の取引が可能になるセカンダリマーケットです。簡単な操作により、日本円で直接売買することができます。`,
        category_id: 3,
    },
    {
        question: `Octillion を利用するには、アカウントの登録は必須ですか？	`,
        answer: `OctillionでNFTの売買をすることや、保有するNFTを確認するためには、Octillionのアカウント及びアニカナウォレットが必要です。`,
        category_id: 3,
    },
    {
        question: `NFTを購入するには何が必要ですか？	`,
        answer: `NFTを購入するにはLEVICAが必要となります。`,
        category_id: 3,
    },
    {
        question: `Octillion を使用したNFTの販売に関連する手数料はいくらですか？	`,
        answer: `アルカナの販売出品者に売上代金の10%の手数料が発生します。\nペルソナの販売出品者に売上代金の10%の手数料が発生します。\nインターネット接続料金その他の電気通信回線の通信に関する費用は、お客様の負担となります。\nまた、当社のサービスの利用には、第三者が提供する所定のウォレットを利用する必要があります。当該ウォレットの利用にかかる費用は、お客様の負担となります。`,
        category_id: 3,
    },
    {
        question: `NFT トランザクションに Octillion を使用する特別な理由なんですか？	`,
        answer: `現在、PERSONAやARCANAを売買できる唯一のマーケットプレイスはOctillionです。`,
        category_id: 3,
    },
    {
        question: `ブロックチェーンとは何ですか？	`,
        answer: `ブロックチェーンは、分散型ネットワーク全体でトランザクションと情報を記録するデジタル分散台帳です。 ブロックチェーンにはさまざまな種類があります。OctillionはANICANA Chainと互換性があります。`,
        category_id: 4,
    },
    {
        question: `ANICANA (アニカナ)とは何ですか？	`,
        answer: `「アニカナ」とは、体験などをデータ化し、ブロックチェーンまたは関連するシステムを使用して経済的な価値に変換することのできるサービスやプロダクト等の総称をいいます。`,
        category_id: 4,
    },
    {
        question: `アニカナチェーンとは何ですか？	`,
        answer: `ANICANAの独自ブロックチェーンです。`,
        category_id: 4,
    }
]

export default {
    categories,
    faqs
}